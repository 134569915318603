import React from 'react'
import { Card, CardContent, CardHeader, Typography, List, ListItem, Box, Button } from '@material-ui/core'
import { Warning as WarningIcon, Folder as FolderIcon } from '@material-ui/icons'
import { useTheme } from '@material-ui/styles'
import { NavLink } from 'react-router-dom'
import { useActions } from '../../../../../utils/action-helper'
import { archiveSetArchiveType, tableSetDialog } from '../../../../../actions'
import { useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'

const RequestComponent = ({
    t,
    archiveFoundInFolderGroup,
    sharedArchivesInGroup,
    archiveFoundInFolderCompany,
    sharedArchivesInCompany,
    textBins,
    allowedLists,
    benchmarksInGroup,
    type,
    warningType ='delete'
}) => {
    const { theme: { bgColors } } = useTheme()
    const dialog = useSelector(state => state.tableData.dialog)

    const { aArchiveSetArchiveType, aTableSetDialog } = useActions({ aArchiveSetArchiveType: archiveSetArchiveType, aTableSetDialog: tableSetDialog })
    const history = useHistory()

    const warningIconStyle = {
        marginRight: '10px',
        color: "rgb(180, 163, 9)"
    }

    const folderIconStyle = {
        marginRight: '6px',
        color: "gray"
    }

    const listItemStyle = {
        backgroundColor: "#FADADD",
        borderRadius: '10px',
        marginBottom: '8px',
        display: 'flex',
        alignItems: 'center'
    }

    const folderItemStyle = {
        display: 'flex',
        alignItems: 'center'
    }

    const warningHeader = `admin_${type}_${warningType}-warning`
    const title = `admin_${type}_${warningType}-${type}`
console.log("warningType", warningType)
    return (
        <Card sx={{ mt: 4, boxShadow: 3, borderRadius: 2 }}>
            <CardHeader
                title={t(title)}
                titleTypographyProps={{ variant: 'h4', align: 'center', color: 'primary' }}
            />
            <CardContent>
                <Typography variant="h6" align="center" gutterBottom>
                    {t(warningHeader)}
                </Typography>
                <List>
                    {archiveFoundInFolderGroup.length > 0 && (
                        <ListItem style={listItemStyle}>
                            <Box>
                                <div className='d-flex'>
                                    <WarningIcon style={warningIconStyle} />
                                    <Typography variant="body1">{
                                        t(`admin_${type}_group-archives{{archiveCount}}{{folderCount}}`, { archiveCount: sharedArchivesInGroup.archives_fullcount, folderCount: archiveFoundInFolderGroup.length })
                                    }</Typography>
                                </div>
                                <Box className='ml-4'>
                                    {archiveFoundInFolderGroup.map((folder, index) => (
                                        <ListItem key={index} style={folderItemStyle}>
                                            <FolderIcon style={folderIconStyle} />
                                            <Typography variant="body2" sx={{ display: 'inline', mr: 1 }}>
                                                {folder}
                                            </Typography>
                                        </ListItem>
                                    ))}
                                </Box>
                            </Box>
                        </ListItem>
                    )}
                    {archiveFoundInFolderCompany && archiveFoundInFolderCompany.length > 0 && (
                        <ListItem style={listItemStyle}>
                            <Box>
                                <div className='d-flex'>
                                    <WarningIcon style={warningIconStyle} />

                                    <Typography variant="body1">
                                        {t(`admin_${type}_company-archives{{archiveCount}}{{folderCount}}`, { archiveCount: sharedArchivesInCompany.archives_fullcount, folderCount: archiveFoundInFolderCompany.length })}
                                    </Typography>
                                </div>
                                <Box className='ml-4'>
                                    {archiveFoundInFolderCompany.map((folder, index) => (
                                        <ListItem key={index} style={folderItemStyle}>
                                            <FolderIcon style={folderIconStyle} />
                                            <Typography variant="body2" sx={{ display: 'inline', mr: 1 }}>
                                                {folder}
                                            </Typography>
                                        </ListItem>
                                    ))}
                                </Box>
                            </Box>
                        </ListItem>
                    )}
                    { benchmarksInGroup && benchmarksInGroup?.fullcount > 0 && (
                        <ListItem style={listItemStyle}>
                            <WarningIcon style={warningIconStyle} />
                            <Typography variant="body1">{t(
                                `admin_${type}_benchmarks{{count}}`,
                                { count: benchmarksInGroup.fullcount }
                            )}</Typography>
                        </ListItem>
                    )}
                    {textBins?.fullcount > 0 && (
                        <ListItem style={listItemStyle}>
                            <WarningIcon style={warningIconStyle} />
                            <Typography variant="body1">{t(
                                `admin_${type}_text-bins{{count}}`,
                                { count: textBins.fullcount }
                            )}</Typography>
                        </ListItem>
                    )}
                    {allowedLists?.fullcount > 0 && (
                        <ListItem style={listItemStyle}>
                            <WarningIcon style={warningIconStyle} />
                            <Typography variant="body1">{t(
                                `admin_${type}_allowed-lists{{count}}`,
                                { count: allowedLists.fullcount }
                            )}</Typography>
                        </ListItem>
                    )}
                </List>
                <Box style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexWrap: 'wrap', rowGap: '15px', marginTop: '10px' }}>
                    {sharedArchivesInGroup.archives_fullcount > 0 && (
                        <Button
                            className={`ml-2 mr-3`}
                            onClick={() => { aArchiveSetArchiveType('group'); aTableSetDialog({...dialog, open: false}); history.push('/archive') }}
                            variant="contained"
                            style={{ backgroundColor: bgColors.bgBlue1000, fontSize: '13px' }} color="primary"
                        >
                            {t(`admin_${type}_go-to-group-archives`)}
                        </Button>
                    )}
                    {sharedArchivesInCompany && sharedArchivesInCompany.archives_fullcount > 0 && (
                        <Button
                            className={`ml-2 mr-3`}
                            onClick={() => { aArchiveSetArchiveType('company'); aTableSetDialog({...dialog, open: false}); history.push('/archive') }}
                            variant="contained"
                            style={{ backgroundColor: bgColors.bgBlue1000, fontSize: '13px' }} color="primary"
                        >
                            {t('admin_user_go-to-company-archives')}
                        </Button>
                    )}
                    {benchmarksInGroup?.fullcount > 0 && (
                        <NavLink to="/administration/text-types" exact={true}>
                            <Button
                                className={`ml-2 mr-3`}
                                variant="contained"
                                onClick={() => aTableSetDialog({...dialog, open: false})}
                                style={{ backgroundColor: bgColors.bgBlue1000, fontSize: '13px' }} color="primary"
                            >
                                {t(`admin_${type}_go-to-benchmarks`)}
                            </Button>
                        </NavLink>
                    )}
                    {textBins?.fullcount > 0 && (
                        <NavLink to="/administration/terminology/textbin" exact={true}>
                            <Button
                                className={`ml-2 mr-3`}
                                variant="contained"
                                onClick={() => aTableSetDialog({...dialog, open: false})}
                                style={{ backgroundColor: bgColors.bgBlue1000, fontSize: '13px' }} color="primary"
                            >
                                {t(`admin_${type}_go-to-text-bins`)}
                            </Button>
                        </NavLink>
                    )}
                    {allowedLists?.fullcount > 0 && (
                        <NavLink to="/administration/terminology/allowedList" exact={true}>
                            <Button
                                className={`ml-2 mr-3`}
                                variant="contained"
                                onClick={() => aTableSetDialog({...dialog, open: false})}
                                style={{ backgroundColor: bgColors.bgBlue1000, fontSize: '13px' }} color="primary"
                            >
                                {t(`admin_${type}_go-to-allowedList`)}
                            </Button>
                        </NavLink>
                    )}
                </Box>
            </CardContent>
        </Card>
    )
}

export default RequestComponent
