import { useContext, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { Paper } from '@material-ui/core'
import TLServiceComponent from "../../../../contexts/TLServiceComponent"
import { useSelector } from "react-redux"
import { checkUserRight, convertJSONToObject, onlyUnique } from "../../../../utils"
import { useActions } from "../../../../utils/action-helper"
import {
  adminGetAllGroups,
  clearAllGroups,
  tableSetData, tableSetDefaults,
  tableSetOtherData,
  tableSetPages,
  tableSetRowCount, tableSetSelectAll, tableSetSelectAllIds,
  userGetAllowLists
} from "../../../../actions"
import Spinner from "../../../Spinner"
import UniversalTable from "../../../common/UniversalTable"
import TableMassActionComponent from "./TableMassActionComponent/TableMassActionComponent"
import TableInnerComponent from "./TableInnerComponent/TableInnerComponent"
import style from './table.module.sass'
import { Redirect } from "react-router-dom"
import UserProfileWrapper from "../../UserProfileWrapper/UserProfileWrapper"
import AddNewTermModal from '../../../ModalWindows/TermModal/TermModal.jsx'



function createData(id, category_type, parent_id, description, name, itemCount, settings, rights, global_visible, locale_name, owner) {
  return { id, category_type, parent_id, description, name, itemCount, settings, rights, global_visible, locale_name, owner }
}

export default function ALTable() {

  const { t } = useTranslation()
  const tlService = useContext(TLServiceComponent)
  const archiveLanguage = useSelector(state => state.userData.archiveLanguage)
  const token = useSelector(state => state.userData.token)

  const filters = useSelector(state => state.tableData.filters)
  const limit = useSelector(state => state.tableData.limit)
  const order = useSelector(state => state.tableData.order || 'asc')
  const orderBy = useSelector(state => state.tableData.orderBy || 0)
  const currentPage = useSelector(state => state.tableData.currentPage)
  const selectAll = useSelector(state => state.tableData.selectAll)
  const rowCount = useSelector(state => state.tableData.rowCount)
  const selectedCompany = useSelector(state => state.tableData.companyId)
  const selectedGroup = useSelector(state => state.tableData.groupId)
  const user = useSelector(state => state.userData.user)
  const textDisabledColumns = useSelector(state => state.userData.user?.user_options?.disabled_archive_columns || '[]')

  const disabledColumns = convertJSONToObject(textDisabledColumns, [])

  const {
    aTableSetDefaults,
    aTableSetData,
    aTableSetOtherData,
    aTableSetRowCount, aTableSetSelectAllIds,
    aTableSetPages, aTableSetSelectAll, aAdminClearAllGroups, aAdminGetAllGroups, actionUserGetAllowLists
  } = useActions({
    aTableSetData: tableSetData,
    aTableSetOtherData: tableSetOtherData,
    aTableSetRowCount: tableSetRowCount,
    aTableSetPages: tableSetPages,
    aTableSetDefaults: tableSetDefaults,
    aTableSetSelectAll: tableSetSelectAll,
    aTableSetSelectAllIds: tableSetSelectAllIds,
    aAdminClearAllGroups: clearAllGroups,
    aAdminGetAllGroups: adminGetAllGroups(tlService),
    actionUserGetAllowLists: userGetAllowLists(tlService),
  })

  const [spinner, setSpinner] = useState(false)
  const [letterRows, setLetterRows] = useState([])
  const [addNewAllowedListModal, setAddNewAllowedListModal] = useState(false)
  const [currentAllowedList, setCurrentAllowedList] = useState()
  const [deleteRecall, setDeleteRecall] = useState()

  const defaultPagination = [
    100,
    500
  ]
  const defaultColumns = [
    {
      id: 'term_category_name',
      type: 'string',
      disablePadding: false,
      label: `${t('admin_al_column-name')}`,
      unSortable: true,
      filter_value: 'term_category_name',
      orderBy_value: 'term_category_name'
    },
    {
      id: 'check_words',
      type: 'string',
      disablePadding: false,
      label: `${t('admin_al_column-search-for')}`,
      filter_value: 'check_words',
      orderBy_value: 'check_words'
    },
    {
      id: 'words',
      type: 'array',
      disablePadding: false,
      label: `${t('admin_al_column-words')}`,
      filter_value: 'words',
      orderBy_value: 'words'
    },
    {
      id: 'user_id',
      type: 'link',
      disablePadding: false,
      label: `${t('admin_al_column-user-id')}`,
      filter_value: 'user_id',
      orderBy_value: 'user_id'
    },

    {
      id: 'state',
      type: 'string',
      disablePadding: false,
      label: `${t('admin_al_column-state')}`,
      filter_value: 'state',
      orderBy_value: 'state'
    },
    {
      id: 'create_time',
      type: 'date',
      disablePadding: false,
      label: `${t('admin_al_create-time')}`,
      filter_value: 'create_time',
      orderBy_value: 'create_time'
    },

  ]
  const defaultFilters = [
    {
      id: 'company_id',
      name: 'Company',
      type: 'catalog',
      searchField: 'company_id',
      searchTemplate: '={value}',
      source: 'companies',
      label: t('Company')
    },
    {
      id: 'group_id',
      name: 'Group',
      type: 'catalog',
      searchField: 'user_group_id',
      searchTemplate: '={value}',
      source: 'groups',
      label: t('Groups')
    },
  ]

  const extraSettings = {
    language: archiveLanguage,
    fullSearchFilter: []
  }
  // Get the allowedlist when User open allowed list Modal:
  useEffect(() => {
    // Call this only on Admin Page:
    actionUserGetAllowLists(token)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // on mount
  useEffect(() => {
    aTableSetDefaults(defaultPagination, defaultFilters, disabledColumns, defaultColumns, 'asc', 'id')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [archiveLanguage])

  useEffect(() => {

    if (selectedCompany) {
      aAdminGetAllGroups(token, selectedCompany)
    } else {
      aAdminClearAllGroups()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCompany])

  const runRequest = async (limit, requestLimits, requestFilters) => {
    // make a request
    let userIds = []
    try {
      const res = await tlService.getFilteredUsers(token, {}, {
        return_values: '["id"]'
      })
      if (res?.data?.length) {
        let arrRet = []
        for (const el of res.data) {
          arrRet.push(el.id)
        }
        userIds = arrRet.filter(onlyUnique)
      }
    } catch (err) {
      console.log(err)
      userIds = []
    }

    try {
      const res = await tlService.getFilteredAllowedList(token, requestLimits, requestFilters)
      if (res?.data?.length) {
        const currentData = res.data.map(d => {
          return { ...d, opened: false, 
            user_deleted: userIds?.includes(d.user_id) ? false : true
           }
        })
        aTableSetData(currentData)
        aTableSetOtherData({ al_fullcount: res.fullcount })
        aTableSetRowCount(res.data.length)
        if (limit > 0) {
          aTableSetPages(Math.ceil(res.fullcount / limit))
        }
      }
      if (res?.data?.length) {
        let arry = []
        res?.data.forEach(el => {
          if (el !== undefined) {
            arry.push(createData(el?.id, el?.category_type, el?.parent_id, el?.description, el?.name, el?.term_count, el?.settings, el?.rights, el?.global_visible, el?.locale_name, el?.owner))
          }
        })
        setLetterRows([...arry])
      }
      return true
    } catch (e) {
      console.log(e)
    }
    aTableSetData([])
    aTableSetOtherData({})
    aTableSetRowCount(0)
  }

  const prepareRequest = (limit, order, orderBy, currentPage, filters) => {
    const requestLimits = {
      limit,
      order: order === 'asc' ? 0 : 1,
      order_by: orderBy ? orderBy : 'id',
      offset: limit * (currentPage - 1),
    }
    const requestFilters = {
      words: filters?.full_text_search_filter?.value && `%${filters?.full_text_search_filter?.value}%`,
      company_id: selectedCompany > 0 ? selectedCompany : undefined,
      user_group_id: selectedGroup > 0 ? selectedGroup : undefined,
      state: 0
    }
    return { requestLimits, requestFilters }
  }


  const makeRequest = async (limit, order, orderBy, currentPage, filters) => {
    const {
      requestLimits,
      requestFilters,
    } = prepareRequest(limit, order, orderBy, currentPage, filters)
    await runRequest(limit, requestLimits, requestFilters)
  }

  const getAllIds = async (fullCount, limit, order, orderBy, currentPage, filters, maxChunk) => {
    const ids = []
    const {
      requestLimits,
      requestFilters
    } = prepareRequest(limit, order, orderBy, currentPage, filters)
    for (let offset = 0; offset < fullCount; offset += maxChunk) {
      const currLimit = { ...requestLimits, limit: maxChunk, offset }
      try {
        const res = await tlService.getFilteredAllowedList(token, currLimit, requestFilters)
        if (res?.data?.length) {
          for (const re of res.data) {
            ids.push(re.id)
          }
        }
      } catch (e) {
        console.log(e)
      }
    }

    return ids.filter(onlyUnique)
  }

  const reloadPage = () => {
    reloadAll(limit, order, orderBy, currentPage, filters, setSpinner)
  }

  function reloadAll(limit, order, orderBy, currentPage, filters, setSpinner) {
    setSpinner(true)
    makeRequest(limit, order, orderBy, currentPage, filters).then(() => setSpinner(false))
  }

  // filter if change
  useEffect(() => {
    reloadAll(limit, order, orderBy, currentPage, filters, setSpinner)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(filters), limit, order, orderBy, currentPage, selectedCompany, selectedGroup])

  const handleSelectAll = async () => {
    setSpinner(true)
    const fullCount = rowCount || 0
    if (!selectAll) {
      const allIds = await getAllIds(fullCount, limit, order, orderBy, currentPage, filters, 1000)
      if (allIds?.length) {
        aTableSetSelectAllIds(allIds)
        aTableSetSelectAll(true)
      }
    } else {
      aTableSetSelectAllIds([])
      aTableSetSelectAll(false)
    }
    setSpinner(false)
  }

  if (!checkUserRight(user, [131, 231, 701, 702, 703]))
    return <Redirect to={"/"} />

  return (
    <UserProfileWrapper>

      <div className={`${style.mainCont}`}>
        <Paper className={`mb-5`}>
          {spinner && <Spinner />}
          <AddNewTermModal
            component="AdminAllowedlist"
            addAPI={tlService.addAllowedList}
            editAPI={tlService.editAllowedList}
            setDeleteRecall={setDeleteRecall}
            currentAllowedList={currentAllowedList}
            open={addNewAllowedListModal}
            setOpen={setAddNewAllowedListModal}
            callEndPoint={reloadPage}
          />
          <div style={{ float: 'right', marginBottom: '10px', marginRight: '8px' }}>
          </div>
          <UniversalTable
            defaultColumns={defaultColumns}
            reloadAll={reloadAll}
            handleSelectAll={handleSelectAll}
            getAllIds={getAllIds}
            noDataText={t('admin_al_no-data')}
            selectAllText={t('admin_al_select-all-terms')}
            MassActionComponent={TableMassActionComponent}
            InnerComponent={TableInnerComponent}
            extraSettings={extraSettings}
            style={style}
            type='allowed-list'
            setTerminologyModal={setAddNewAllowedListModal}
            setCurrentTerm={setCurrentAllowedList}
            callEndPoint={reloadPage}
            enableExtraFilters
          />
        </Paper>
      </div>
    </UserProfileWrapper>
  )
}