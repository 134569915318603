import { convertFromErrorObject } from '../../../../utils'
import * as XLSX from 'xlsx'


const putXLSX = (dataResult, filename) => {
  const wb = XLSX.utils.book_new()
  XLSX.utils.book_append_sheet(wb, XLSX.utils.json_to_sheet(dataResult), 'Sheet1')
  const element = document.createElement('a')
  const bin = XLSX.write(wb, { bookType: 'xlsx', type: 'array' })
  element.href = URL.createObjectURL(new Blob([bin], { type: 'application/vnd.ms-excel' }))
  element.download = filename
  document.body.appendChild(element) // Required for this to work in FireFox
  element.click()
}


const putSimpleXLSX = (dataResult, filename) => {
  const wb = XLSX.utils.book_new()
  XLSX.utils.book_append_sheet(wb, XLSX.utils.json_to_sheet(dataResult), 'Sheet1')
  const element = document.createElement('a')
  const bin = XLSX.write(wb, { bookType: 'xlsx', type: 'array' })
  element.href = URL.createObjectURL(new Blob([bin], { type: 'application/vnd.ms-excel' }))
  element.download = filename
  document.body.appendChild(element) // Required for this to work in FireFox
  element.click()
}

const putSimpleCSV = (dataResult, filename) => {
  const columns = ['id', 'user_name', 'email', 'first_name', 'last_name', 'user_group_name','company_name','street', 'house_number', 'zip_code', 'user_group_id', 'company_id', 'company_name', 'country_id', 'user_type_id', 'user_type_name', 'language_id', 'city', 'enabled', 'license_id']
  

  let stringResult = columns.join(',') + '\n'
  for (const res of dataResult) {
    let tmpString = ''
    for (const col of columns) {
      tmpString += res[col] !== undefined ? '"' + res[col].toString().replaceAll('"', '""') + '",' : '"",'
    }
    stringResult += tmpString.substring(0, tmpString.length - 1) + '\n'
  }

  const blob = new Blob(['\ufeff' + stringResult.substring(0, stringResult.length - 1)], { type: 'text/csv' })
  const element = document.createElement('a')
  element.href = URL.createObjectURL(blob)
  element.download = filename
  document.body.appendChild(element) // Required for this to work in FireFox
  element.click()
}

const putCSV = (dataResult, filename) => {
  let columns = ['id', 'user_group_id', 'user_group_name', 'company_id', 'company_name', 'country_id', 'user_type_id', 'user_type_name', 'language_id', 'user_name', 'email', 'first_name', 'last_name', 'street', 'house_number', 'zip_code', 'city', 'enabled', 'license_id']
  

  let stringResult = columns.join(',') + '\n'
  for (const res of dataResult) {
    let tmpString = ''
    for (const col of columns) {
      tmpString += res[col] !== undefined ? '"' + res[col].toString().replaceAll('"', '""') + '",' : '"",'
    }
    stringResult += tmpString.substring(0, tmpString.length - 1) + '\n'
  }

  const blob = new Blob(['\ufeff' + stringResult.substring(0, stringResult.length - 1)], { type: 'text/csv' })
  const element = document.createElement('a')
  element.href = URL.createObjectURL(blob)
  element.download = filename
  document.body.appendChild(element) // Required for this to work in FireFox
  element.click()
}

export const downloadUsers = async (tlService, token, setDownloadBarVisible, setDownloadBarNow, downloadFormat, selectedUsers) => { 
  let limit = 1000
  let offset = -limit
  let fullcount = 0
  let dataResult = []
  setDownloadBarVisible(true)
  do {
    offset += limit
    let limits = {limit, offset}
    let filters = {}
    if (selectedUsers.length > 0) {
      filters['extended_filter'] = JSON.stringify([["&id", `i=${JSON.stringify(selectedUsers)}`]])
    }
   try{
    const userData = await tlService.getFilteredUsers(token, limits, filters)
    if (userData['data'] && userData['data'].length > 0) {
      fullcount = userData['fullcount']
      // convert data
      for (const dataTmp of userData['data']) {
        let returnVal = {}
        for (const [key, value] of Object.entries(dataTmp)) {
        if (key === 'id' && (value !== undefined || value !== null)) {
          returnVal['id'] = value
        } else if (key === 'user_group_id' && (value !== undefined || value !== null)) {
          returnVal['user_group_id'] = value
        } else if (key === 'user_group_name' && value.length > 0) {
          returnVal['user_group_name'] = JSON.stringify(value)
        } else if (key === 'company_id' && (value !== undefined || value !== null)) {
          returnVal['company_id'] = value
        } else if (key === 'company_name' && value.length > 0) {
          returnVal['company_name'] = JSON.stringify(value)
        } else if (key === 'country_id' && value.length > 0) {
          returnVal['country_id'] = JSON.stringify(value)
        } else if (key === 'user_type_id' && (value !== undefined || value !== null)) {
          returnVal['user_type_id'] = value
        } else if (key === 'user_type_name' && value.length > 0) {
          returnVal['user_type_name'] = JSON.stringify(value)
        } else if (key === 'language_id' && value.length > 0) {
          returnVal['language_id'] = JSON.stringify(value)
        } else if (key === 'user_name' && value.length > 0) {
          returnVal['user_name'] = JSON.stringify(value)
        } else if (key === 'email' && value.length > 0) {
          returnVal['email'] = JSON.stringify(value)
        } else if (key === 'first_name' && value.length > 0) {
          returnVal['first_name'] = JSON.stringify(value)
        } else if (key === 'last_name' && value.length > 0) {
          returnVal['last_name'] = JSON.stringify(value)
        } else if (key === 'street' && value.length > 0) {
          returnVal['street'] = JSON.stringify(value)
        } else if (key === 'house_number' && value.length > 0) {
          returnVal['house_number'] = JSON.stringify(value)
        } else if (key === 'zip_code' && value.length > 0) {
          returnVal['zip_code'] = JSON.stringify(value)
        } else if (key === 'city' && value.length > 0) {
          returnVal['city'] = JSON.stringify(value)
        } else if (key === 'enabled' && (value !== undefined || value !== null)) {
          returnVal['enabled'] = value
        } else if (key === 'license_id' && (value !== undefined || value !== null)) {
          returnVal['license_id'] = value
        }
}
        dataResult.push(returnVal)
      }
      setDownloadBarNow(Math.round((offset + limit) / (fullcount / 100)) )
    }
    else {
      break
    } 
   } catch (err) {
    console.log(err)
    break
   }
  }
  while (fullcount > (offset + limit))
  setDownloadBarVisible(false)
  if (downloadFormat === 'CSV')
    putCSV(dataResult, `downloadUser.csv`)
  else if (downloadFormat === 'XLSX')
    putXLSX(dataResult, `downloadUser.xlsx`)
  else if (downloadFormat === 'SimpleXLSX')
    putSimpleXLSX(dataResult, `simpleDownloadUser.xlsx`)
  else if (downloadFormat === 'SimpleCSV')
    putSimpleCSV(dataResult, `simpleDownloadUser.csv`)
}

export const runUploadUsers = async (tlService, token, data, t, updateAlert) => {

    const { user_group_id, country_id, user_type_id, language_id, user_name, email, first_name, last_name, street, house_number, zip_code, city, enabled, license_id, password } = data
    if (user_name !== undefined) {
      try {
        await tlService.addUser( token, user_group_id, country_id, user_type_id, language_id, user_name, email, password, first_name,
          last_name, street, house_number, zip_code, city, enabled, license_id)
      } catch (err) {
        updateAlert(convertFromErrorObject(t, err), user_name)
        return false
      }
    } 
  return true
}


export const clrModal = (setUploadBarNow, setUploadBarVisible, setDisableUpload, setDisableButton, setUploadFile, setUploadText, setFullCounterText, t) => {
  setUploadBarNow(0)
  setUploadBarVisible(false)
  setDisableUpload(false)
  setDisableButton(true)
  setUploadFile(null)
  setFullCounterText('')
  setUploadText(t('Data Upload'))
}

