import React, { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import TextGenButton from './TextGenButton'
import TinyEditor from '../../../TinyEditor'
import TextTypeCardSmall from './TextTypeCardSmall'
import config from '../../../../config'
import { FormControl, Input, MenuItem, Select, useTheme } from '@material-ui/core'
import AiFooter from './AiFooter'
import {
  checkAiText,
  checkTextSize,
  convertLanguagesToFull,
  isObjectEmpty,
  isObjectsEqual,
  stripHtml,
  textClear
} from '../../../../utils'
import { Check } from '@material-ui/icons'
import ComponentWithPop from '../../../common/ComponentWithPop'
import { useActions } from '../../../../utils/action-helper'
import {
  analyseSetAiLocalResult,
  analyseSetEnabledBenchmark,
  analyseSetLocalResult,
  analyseSetResult,
  analyseSetText,
  analyseSetTextLanguage,
  analyseSetUseBenchmark
} from '../../../../actions'
import { useSelector } from 'react-redux'
import { filterBenchmark, startAnalyse } from '../../analysisFunctions'
import TLServiceComponent from '../../../../contexts/TLServiceComponent'
import RoboSpinner from '../../../RoboSpinner'
import ErrorModal from '../../ModalWindows/ErrorModal'
import { useHistory } from 'react-router-dom'
import TopIndicatorsBarAi from '../../TopIndicatorsBar/TopIndicatorsBarAI'
import CloseIcon from '@material-ui/icons/Close'

const GeneratorResult = ({
  message,
  handleMessageChange,
  selectedBenchmark,
  handleCardSelection,
  selectedCardId,
  card
}) => {
  const tlService = useContext(TLServiceComponent)
  const { t, i18n: { language: i18nLanguage } } = useTranslation()
  const { theme: { bgColors, colors } } = useTheme()
  const {
    actionAnalyseSetTextLanguage,
    actionAnalyseSetUseBenchmark,
    actionAnalyseSetEnabledBenchmark,
    actionAnalyseSetResult,
    actionAnalyseSetText,
    aAnalyseSetLocalResult
  } = useActions({
    actionAnalyseSetTextLanguage: analyseSetTextLanguage,
    actionAnalyseSetUseBenchmark: analyseSetUseBenchmark,
    actionAnalyseSetEnabledBenchmark: analyseSetEnabledBenchmark,
    aAnalyseSetAiLocalResult: analyseSetAiLocalResult,
    aAnalyseSetLocalResult: analyseSetLocalResult,
    actionAnalyseSetText: analyseSetText,
    actionAnalyseSetResult: analyseSetResult
  })
  const enabledBenchmarks = useSelector(state => state.analyseData.enabledBenchmarks)
  const useBenchmark = useSelector(state => state.analyseData.useBenchmark)
  const benchmarks = useSelector(state => state.userData.benchmarks)
  const textLanguage = useSelector(state => state.analyseData.textLanguage)
  const aiMaxTextSize = useSelector((state) => state.userData.user.ai_settings?.max_text_size)
  const reduxCurrentBenchmarkSettings = useSelector(state => state.analyseData.result.benchmark)
  const localResult = useSelector(state => state.analyseData.result.localAnalyseResultData)
  const cardData = useSelector(state => state.userData?.user?.ai_settings?.text_generator)
  const token = useSelector(state => state.userData.token)

  const [submitted, setSubmitted] = useState(false)
  const [error, setError] = useState(null)
  const [localBenchmark, setLocalBenchmark] = useState(reduxCurrentBenchmarkSettings)

  const [analyseButtonEnable, setAnalyseButtonEnable] = useState(false)
  const [enableButtonBack, setEnableButtonBack] = useState(false)
  const [translatedText, setTranslatedText] = useState('')
  const [showBar, setShowBar] = useState(false)
  const [localAnalyseResult, setLocalAnalyseResult] = useState({})
  const [newSelectedBenchmark, setNewSelectedBenchmark] = useState(null)
  const [historyArray, setHistoryArray] = useState([])
  const [promptValues, setPromptValues] = useState(card.settings?.reduce((acc, setting) => ({
    ...acc,
    [setting.name]: setting.default
  }), {}))

  const cardHistory = card?.history || false
  const history = useHistory()
  const regEmpty = /\w/

  useEffect(() => {
    setPromptValues(card.settings?.reduce((acc, setting) => ({ ...acc, [setting.name]: setting.default }), {}))
  }, [JSON.stringify(card.settings)])

  useEffect(() => {
    actionAnalyseSetTextLanguage(
      benchmarks[selectedBenchmark]?.locale_name || convertLanguagesToFull(i18nLanguage)
    )
    if (newSelectedBenchmark) {
      actionAnalyseSetUseBenchmark(newSelectedBenchmark)
    } else {
      actionAnalyseSetUseBenchmark(selectedBenchmark || (!isObjectEmpty(enabledBenchmarks) ? parseInt(Object.keys(enabledBenchmarks)[0]) : 0))
    }

    if (regEmpty.test(translatedText)) {
      setEnableButtonBack(true)
    } else {
      setEnableButtonBack(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [benchmarks, textLanguage, translatedText, selectedBenchmark])

  useEffect(() => {
    if (!message.length > 0 || !selectedBenchmark) {
      setAnalyseButtonEnable(false)
    } else {
      setAnalyseButtonEnable(true)
    }
  }, [message.length, selectedBenchmark, promptValues.tonality, promptValues.text_length])

  useEffect(() => {
    if (!isObjectEmpty(benchmarks)) {
      const filteredBenchmark = filterBenchmark(benchmarks, textLanguage, 'locale_name')
      if (!isObjectsEqual(filteredBenchmark, enabledBenchmarks)) {
        actionAnalyseSetEnabledBenchmark(filteredBenchmark)
      }
    }
    // eslint-disable-next-line
  }, [benchmarks, textLanguage])

  useEffect(() => {
    handleOurAi(message)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleReset = () => {
    handleMessageChange({ target: { value: '' } })
    setHistoryArray([])
  }

  const handleOnEditorChange = (newText) => {
    newText = newText.replace(/\xAD/g, '')
    setTranslatedText(newText)
    if (cardHistory && historyArray.length > 0) {
      //update the latest history
      setHistoryArray(prevHistoryArray => {
          const updatedArray = [...prevHistoryArray]
          updatedArray[updatedArray.length - 1][1] = textClear(newText)
          return updatedArray
        }
      )
    }
  }

  function handleOnLanguageChange (value) {
    actionAnalyseSetTextLanguage(value)
  }

  function handleOnTextTypeChange (value) {
    if (!isObjectEmpty(enabledBenchmarks) && value !== useBenchmark && enabledBenchmarks[value] !== undefined) {
      actionAnalyseSetUseBenchmark(value)
      setNewSelectedBenchmark(value)
    }
  }

  const wordCount = (text) => {
    return textClear(text) ? textClear(text).split(/\s+/).length : 0
  }

  const localAnalyse = async (clearText) => {

    let localBenchmark = useBenchmark
    let localTextLanguage = textLanguage

    if (localBenchmark === 0) {
      if (benchmarks[selectedBenchmark]) {
        localBenchmark = selectedBenchmark
        localTextLanguage = benchmarks[selectedBenchmark]?.locale_name
      } else {
        return false
      }
    }

    const checkedSizeError = checkTextSize(config, clearText.length, stripHtml(clearText).length)
    if (checkedSizeError) {
      setError({ text: t(checkedSizeError), header: t(checkedSizeError) })
      return false
    }
    let result
    try {
      result = await startAnalyse(localBenchmark, localTextLanguage, clearText,
        tlService, token, t)
    } catch (e) {
      console.log('error', e)
      return false
    }
    result.error && console.log('result.error ', result.error)
    setError(result.error)
    aAnalyseSetLocalResult(result.resultData)
    setLocalBenchmark(benchmarks[result.resultTemplate])
    setLocalAnalyseResult(result)
    setSubmitted(false)
    setShowBar(true)
  }

  const handleOurAi = async (textOriginal) => {

    const generateReplacementValue = (setting, valuesFromState, i) => {
      switch (setting.type) {
        case 'select':
          const currentSetting = setting.values.find(value => value.id === valuesFromState[setting.name])
          if (typeof currentSetting.prompt === 'object') {
            if (currentSetting.prompt.length > i) {
              return currentSetting.prompt[i]
            } else {
              return ''
            }
          }
          return currentSetting?.prompt || ''
        case 'integer':
          if (typeof setting.prompt === 'object') {
            if (setting.prompt.length > i) {
              return setting.prompt[i].replace('{{number}}', String(valuesFromState[setting.name]))
            } else {
              return ''
            }
          }
          return setting.prompt.replace('{{number}}', String(valuesFromState[setting.name])) || ''
        default:
          if (typeof valuesFromState[setting.name] === 'object') {
            if (valuesFromState[setting.name].length > i) {
              return valuesFromState[setting.name][i]
            } else {
              return ''
            }
          }
          return valuesFromState[setting.name] || ''
      }
    }

    setSubmitted(true)
    setError(null)
    const text = textClear(textOriginal)

    if (!text?.length) {
      setError({ text: t('ai_text_too_short'), header: t('ai_header_text_too_short') })
      setSubmitted(false)
      return false
    }

    if (!checkAiText(text, aiMaxTextSize)) {
      setError({ text: t('ai_text_too_long'), header: t('ai_header_text_too_long') })
      setSubmitted(false)
      return false
    }

    let promptToSend = card.prompt
    let textResult = text

    if (typeof promptToSend === 'string') {
      promptToSend = [promptToSend]
    }

    // prompt chain
    for (let i = 0; i < promptToSend.length; i++) {

      // prepare the values
      for (const setting of card.settings) {
        const replacementValue = generateReplacementValue(setting, promptValues, i)
        promptToSend[i] = promptToSend[i].replace(`{{${setting.name}}}`, replacementValue)
      }

      // run chain
      try {
        const requestBody = { user: textResult, system: promptToSend[i] }
        const res = await tlService.runRedirect(token, { body: requestBody }, 'Assistant')
        if (res?.data?.text) {
          textResult = res.data.text.replace(/^\n/, '').replace(/\n$/, '')
        }
      } catch (e) {
        console.log('handleOurAi', e)
        setError({ text: t('ai_timeout_error'), header: t('ai_header_timeout_error') })
        setSubmitted(false)
        return false
      }

    }

    const arrText = textResult.split('\n\n')

    let res = ''
    for (const t of arrText) {
      res += `<p>${t.replaceAll(/\n/g, '<br />')}</p>`
    }

    res = res.replaceAll(/\n/g, '<br>')

    setTranslatedText(res)

    await localAnalyse(res)
    setSubmitted(false)

    return true
  }

  const handleSendBack = () => {
    actionAnalyseSetText(translatedText)
    actionAnalyseSetResult(localAnalyseResult.resultData, localAnalyseResult.resultTemplate,
      benchmarks[localAnalyseResult.resultTemplate])
    localAnalyse(translatedText)
    localAnalyse(translatedText, true)
    history.push('/')
  }
  const handleRefreshAnalyse = () => {
    localAnalyse(translatedText)
  }

  return (
    <div style={{ flex: '1 0 auto' }} className="d-flex justify-content-between mt-5">
      {submitted && <RoboSpinner text={t('ai_gen_spinner-text')}/>}
      <ErrorModal error={error} setError={setError}/>
      <div className="mr-4">
        <div style={{ width: '411px', position: 'relative', backgroundColor: 'white', borderRadius: '8px' }}>
          <p style={{
            position: 'absolute',
            top: '0',
            left: '5px',
            backgroundColor: 'white',
            padding: '0 5px',
            fontSize: '12px'
          }}>{t('ai_gen_header')}</p>
          <textarea
            placeholder={t('ai_gen_type-your-message')}
            rows="6"
            value={message}
            onChange={handleMessageChange}
            style={{
              width: '400px',
              resize: 'none',
              border: '1px solid #ccc',
              borderRadius: '8px',
              padding: '10px',
              fontSize: '16px',
              marginTop: '10px',
              marginLeft: '5px'
            }}
          />
          <CloseIcon style={{ position: 'absolute', top: '10px', left: '385px', fontSize: '16px', cursor: 'pointer' }}
                     onClick={handleReset}/>
        </div>
        <div className="d-flex mt-2 flex-wrap ml-3" style={{ maxWidth: '600px' }}>
          {
            cardData?.map((card) => (
              <TextTypeCardSmall key={card.id} title={card.id} img={card.icon} texttype={card.benchmark} card={card}
                                 selectedBenchmark={selectedBenchmark} selectedCardId={selectedCardId}
                                 handleCardSelection={handleCardSelection}/>
            ))
          }
        </div>
        <div className="d-flex flex-wrap" style={{ maxWidth: '500px' }}>
          {
            card.settings.map((setting, index) => (
              <div key={index}>
                {setting.type === 'select' ? (
                  <FormControl className={`ml-1 d-flex justify-content-center`}
                               style={{
                                 width: '180px',
                                 fontSize: '14px',
                                 marginTop: '10px',
                                 marginLeft: '5px',
                                 backgroundColor: 'white',
                                 borderRadius: '5px',
                                 height: '60px',
                                 padding: '0 2px',
                                 position: 'relative'
                               }}>
                    <p style={{
                      position: 'absolute',
                      top: '0',
                      left: '5px',
                      backgroundColor: 'white',
                      padding: '0 5px',
                      fontSize: '10px',
                      zIndex: 1
                    }}>
                      {t(`ai_gen_${setting.name}`)}
                    </p>
                    <Select
                      value={promptValues[setting.name] || ''}
                      onChange={(e) => setPromptValues({ ...promptValues, [setting.name]: e.target.value })}
                      style={{
                        border: '1px solid #ccc',
                        borderRadius: '5px',
                        height: '50px',
                        padding: '0 5px',
                      }}
                    >
                      {
                        setting.values.map((value, index) => (
                          <MenuItem key={index} value={value.id}>{t(value.text)}</MenuItem>
                        ))
                      }
                    </Select>
                  </FormControl>
                ) : (

                  <FormControl className={`ml-1 d-flex justify-content-center`}
                               style={{
                                 width: '180px',
                                 fontSize: '14px',
                                 marginTop: '10px',
                                 marginLeft: '5px',
                                 backgroundColor: 'white',
                                 borderRadius: '5px',
                                 height: '60px',
                                 padding: '0 2px',
                                 position: 'relative'
                               }}
                  >
                    <p style={{
                      position: 'absolute',
                      top: '0',
                      left: '5px',
                      backgroundColor: 'white',
                      padding: '0 5px',
                      fontSize: '10px',
                      zIndex: 1
                    }}>{t('ai_gen_text-length')}</p>
                    <Input
                      type="number"
                      id="demo-simple-select"
                      value={promptValues[setting.name] || ''}
                      onChange={(e) => setPromptValues({ ...promptValues, [setting.name]: e.target.value })}
                      style={{
                        border: '1px solid #ccc',
                        borderRadius: '5px',
                        height: '50px',
                        padding: '0 5px',
                      }}
                    />
                  </FormControl>
                )}
              </div>
            ))
          }

        </div>
        <TextGenButton
          handleOnAnalyseClick={() => handleOurAi(message)}
          analyseButtonEnable={analyseButtonEnable}
        />
      </div>
      <div style={{ width: '90%' }}>
        <div style={{ backgroundColor: 'white', borderRadius: '8px' }}>
          {
            showBar && (
              <div className="d-flex justify-content-between p-2">
                <div style={{ margin: '2px 3px' }}>
                  {<TopIndicatorsBarAi extResultData={localResult} extCurrentBenchmarkSettings={localBenchmark}
                                       handleRefresh={handleRefreshAnalyse}/>}
                </div>
              </div>
            )
          }
          <div className="position-relative">
            <TinyEditor
              initialValue=""
              text={translatedText}
              editorId="second"
              handleOnSelectorChange={() => { }}
              handleOnEditorKeyUp={() => { }}
              handleOnEditorInit={() => { }}
              handleOnEditorChange={handleOnEditorChange}
              handleOnMouseOver={() => { }}
              handleOnMouseClick={() => { }}
            />


            <div style={{ position: 'absolute', top: 8, right: 10, zIndex: 2 }} className="d-flex">

              <ComponentWithPop text={`${t('ai_accept_text')}`}>
                <button disabled={!enableButtonBack} onClick={handleSendBack}
                        style={{ backgroundColor: enableButtonBack ? '#71a141' : bgColors.bgPrimary400 }}
                        className="d-flex align-items-center px-2 py-1">
                  <Check style={{
                    cursor: enableButtonBack ? 'pointer' : 'inherit',
                    color: bgColors.bgPrimary0,
                    fontSize: 30
                  }}/>
                </button>
              </ComponentWithPop>
            </div>
          </div>

        </div>
        <div className="d-flex mt-2 ml-2">
          <p style={{ width: '30%', marginTop: '3px' }}>{t('ai_gen_word-count')}: <b
            style={{ color: colors.colorProgress20 }}>{wordCount(translatedText)}</b></p>
          <AiFooter
            handleOnLanguageChange={handleOnLanguageChange}
            handleOnTextTypeChange={handleOnTextTypeChange}
          />
        </div>
      </div>
    </div>
  )
}

export default GeneratorResult