import { useTheme } from "@material-ui/styles"
import { Fragment, useContext, useEffect, useState } from "react"
import TLServiceComponent from "../../../../../contexts/TLServiceComponent"
import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"
import { useActions } from "../../../../../utils/action-helper"
import {
  tableSetData,
  tableSetDialog,
  tableSetSelected,
} from "../../../../../actions"
import style from './style.module.sass'
import TableContainer from '@material-ui/core/TableContainer'
import { Delete, Settings } from '@material-ui/icons'
import { NavLink } from "react-router-dom"
import TableHead from '@material-ui/core/TableHead'
import { checkSharedData, convertFromErrorObject, convertedValue, isObjectEmpty } from "../../../../../utils"
import { Box, Button, Checkbox, Collapse, Table, TableBody, TableCell, TableRow } from "@material-ui/core"
import Spinner from "../../../../Spinner"
import RequestComponent from "./ReqComponent"


export default function TableInnerComponent({
  row,
  index,
  isBlueRow
}) {

  const { theme: { bgColors, colors } } = useTheme()
  const { t, i18n: { language: i18nLanguage } } = useTranslation()
  const tlService = useContext(TLServiceComponent)

  const selected = useSelector(state => state.tableData.selected)
  const disabledColumns = useSelector(state => state.tableData.disabledColumns)
  const data = useSelector(state => state.tableData.data)
  const columns = useSelector(state => state.tableData.columns)
  const token = useSelector(state => state.userData.token)


  const [extraData, setExtraData] = useState({})
  const [spinner, setSpinner] = useState(false)

  const {
    aTableSetSelected,
    aTableSetData,
    aTableSetDialog
  } = useActions({
    aTableSetData: tableSetData,
    aTableSetSelected: tableSetSelected,
    aTableSetDialog: tableSetDialog,
  })

  const dialogType = useSelector(state => state.tableData.dialog.type)
  const dialogOpen = useSelector(state => state.tableData.dialog.open)
  const dialogResponse = useSelector(state => state.tableData.dialog.response)

  useEffect(() => {
    async function fetchData() {
      if (!dialogOpen && dialogType) {
        switch (dialogType) {
          case 'deleteUser':
            if (dialogResponse && !isObjectEmpty(extraData)) {
              const user_id = extraData.id
              try {
                await tlService.deleteUser(token, user_id)
                const tData = [...data]
                tData.splice(index, 1)
                aTableSetData(tData)
              } catch (error) {
                console.log('deleteUser', error)
                aTableSetDialog({
                  type: 'errorDeleteUser',
                  open: true,
                  RequestComponent: () => <>{convertFromErrorObject(t, error)}</>,
                  response: null,
                  buttons: {
                    yes: false,
                    no: false,
                    close: true,
                    save: false
                  }
                })
              }
            }
            break
          default:
            break
        }
        setExtraData({})
      }
    }
    fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dialogOpen, dialogType])

  const handleSelectItem = (id) => {
    const tSelected = [...selected]
    const idx = tSelected.indexOf(id)
    if (idx > -1) {
      tSelected.splice(idx, 1)
    } else {
      tSelected.push(id)
    }
    aTableSetSelected(tSelected.sort())
  }

  const handleOpenItem = (inx) => {
    const tData = [...data]
    tData[inx]['opened'] = !row?.opened
    aTableSetData(tData)
  }

  const handleDeleteUser = async (row) => {
    setExtraData(row)
    setSpinner(true)
    
    let { textBins, allowedLists, sharedArchivesInGroup, sharedArchivesInCompany, archiveFoundInFolderGroup, archiveFoundInFolderCompany } = await checkSharedData(tlService, token, row, t)

    setSpinner(false)

    if (textBins?.fullcount > 0 || allowedLists?.fullcount > 0 || sharedArchivesInGroup.archives_fullcount > 0 || sharedArchivesInCompany.archives_fullcount > 0) {
      aTableSetDialog({
        type: 'deleteUserWarning',
        open: true,
        RequestComponent: () => (
          <RequestComponent
            t={t}
            archiveFoundInFolderGroup={archiveFoundInFolderGroup}
            sharedArchivesInGroup={sharedArchivesInGroup}
            archiveFoundInFolderCompany={archiveFoundInFolderCompany}
            sharedArchivesInCompany={sharedArchivesInCompany}
            textBins={textBins}
            allowedLists={allowedLists}
            type='user'
          />
        ),
        response: null,
        buttons: {
          yes: false,
          no: false,
          close: true,
          save: false,
          edit: true,
        }
      })

    }
    else {
      aTableSetDialog({
      type: 'deleteUser',
      open: true,
      RequestComponent: () => <>{t('admin_user_delete-user')}</>,
      response: null,
      buttons: {
        yes: true,
        no: true,
        close: false,
        save: false
      }
    })
    }
  }

  const EmptyCell = () => {
    return <TableCell
      onClick={() => handleOpenItem(index)}
      className={style.emptyColumns}
      component="th"
      scope="row"
      padding="none" />
  }

  /* VIEW */
  const isItemSelected = selected.includes(row.id)

  if (spinner)
    return <div className={'w-100 align-content-center text-center align-items-center'}><Spinner /></div>

  return (
    <>
      <TableRow
        style={{ backgroundColor: isItemSelected ? bgColors.bgBlue300 : isBlueRow ? bgColors.bgPrimary0 : bgColors.bgBlue100 }}
        hover
        role="checkbox"
        aria-checked={isItemSelected}
        tabIndex={-1}
        key={row.id}
      >
        <TableCell className={style.cell} padding="checkbox" onClick={() => handleSelectItem(row.id)}>
          <Checkbox
            checked={isItemSelected}
            color="primary"
            inputProps={{ 'aria-labelledby': `enhanced-table-checkbox-${index}` }}
          />
        </TableCell>
        {columns.map((col, ind) =>
          <Fragment key={ind}>
            {disabledColumns.includes(col.id) ? <EmptyCell /> :
              <>
                <TableCell
                  onClick={() => handleOpenItem(index)}
                  className={style.otherColumns}
                  component="th"
                  scope="row"
                  padding="none">
                  {
                    convertedValue(row[col.id], col.type, i18nLanguage, t)
                  }
                </TableCell>
              </>
            }
          </Fragment>)
        }
        <TableCell className={`${style.cell} d-flex`} align="right">
          <div className={style.buttonCell}>
            <Button variant="contained" color="primary" size="small"
              style={{
                padding: '4px',
                minWidth: '30px',
                backgroundColor: bgColors.bgProgress20,
                color: colors.colorPrimary0
              }}
              onClick={() => handleDeleteUser(row)}>
              <Delete fontSize="small" />
            </Button>
          </div>
          <div className={style.buttonCell}>

            <NavLink to={`/administration/user/update/${row.id}`} exact={true}>
              <Button variant="contained" color="primary" size="small"
                style={{
                  padding: '4px',
                  minWidth: '30px',
                  backgroundColor: bgColors.bgBlue1000,
                  color: colors.colorPrimary0
                }}>
                <Settings fontSize="small" />
              </Button>
            </NavLink>
          </div>
        </TableCell>
      </TableRow>
      <TableRow className={`${style.accordion}`}
        style={{ backgroundColor: isItemSelected ? bgColors.bgBlue300 : isBlueRow ? bgColors.bgPrimary0 : bgColors.bgBlue100 }}>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={12}>
          <Collapse className={'p-1'} in={row.opened} timeout="auto" unmountOnExit>
            <Box style={{ color: colors.colorPrimary500, gridGap: 5 }}
              className={`mb-4 d-flex justify-content-between pt-2`}
              margin={1}>
              <div className={'d-flex text-center w-100'}>
                <div style={{ width: '100%', maxHeight: '200px', overflowY: 'scroll' }}>
                  <TableContainer>
                    <Table style={{ border: '1px solid rgba(224, 224, 224, 1)' }}>
                      <TableHead>
                        <TableRow>
                          <TableCell className={style.smallTableHeader} style={{ width: '15%' }}>{t('admin_user_id')}</TableCell>
                          <TableCell className={style.smallTableHeader} style={{ width: '25%' }}>{t('admin_user_first-name')}</TableCell>
                          <TableCell className={style.smallTableHeader} style={{ width: '30%' }}>{t('admin_user_last-updated')}</TableCell>
                          <TableCell className={style.smallTableHeader} style={{ width: '30%' }}>{t('admin_user_email')}</TableCell>

                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {row.opened &&
                          <Fragment key={row.id}>
                            <TableRow
                              key={row.id}>
                              <TableCell
                                className={style.smallTable}>
                                {convertedValue(row.id, 'numeric', i18nLanguage, t)}</TableCell>
                              <TableCell
                                className={style.smallTable}>{convertedValue(row.first_name, 'string', i18nLanguage, t)}</TableCell>
                              <TableCell
                                className={style.smallTable}>{convertedValue(row.update_time, 'date', i18nLanguage, t)}</TableCell>
                              <TableCell
                                className={style.smallTable}>{convertedValue(row.email, 'string', i18nLanguage, t)}</TableCell>
                            </TableRow>
                          </Fragment>}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
                <div>
                </div>
              </div>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  )
}