import {
  arrayAddBodyBorders,
  checkTextSize,
  convertFromErrorObject,
  convertJSONToObject,
  convertLanguagesToShort,
  convertPlainToHTML,
  deepCopy,
  findToNotHTML,
  isObjectEmpty,
  isObjectsEqual,
  mammothAsync,
  readFileAsync,
  removeTagMark,
  stripHtml,
  textAddBodyBorders,
  textAddDataIgnoredArray,
} from '../../utils'
import config from '../../config'

export async function getFromLanguageTool (tlService, token, language, text, resultData) {

  //const excludeList = benchmarkSettings?.settings?.languageTool?.values?.excludeList || [];
  // prepare allowlist dataAllowedList
  let preparedAllowList = {}
  if (resultData?.dataAllowedList && !isObjectEmpty(resultData?.dataAllowedList)) {
    preparedAllowList = Object.keys(resultData.dataAllowedList).reduce((prev, cur) => {
      if (resultData.dataAllowedList[cur]?.category?.relations?.length > 0) {
        if (resultData.dataAllowedList[cur].category.relations.indexOf('languageTool') > -1) {
          let tSendRes = []
          for (let i of resultData.dataAllowedList[cur].result) {
            const wordcount = i.term.wordcount
            for (let pos of i.position) {
              tSendRes.push([
                resultData.dataTokenizer[pos].begin,
                resultData.dataTokenizer[pos + wordcount - 1].end
              ])
            }
          }
          return { ...prev, [cur]: tSendRes }
        }
      }
      return prev
    }, {})
  }

  console.log('preparedAllowList', preparedAllowList)
  // let langTool = false;
  // try {
  //     langTool = await tlService.ltCheck(token, convertLanguagesToFull(language).replace('_', '-'), text, preparedAllowList, excludeList);
  // } catch (e) {
  //     console.log('langTool error', e);
  //     return [];
  // }

  // if (langTool?.result?.matches?.length) {
  //     langTool.result.matches.sort((a, b) => b.offset - a.offset);
  //     return langTool.result.matches
  // }
  return []
}

//ignoreInText
export function ignoreInText (editor, popups, place, replacement, everywhereText) {
  if (everywhereText !== '') {
    for (let i in popups) {
      const element = editor.dom.select('mark#id' + i.toString())[0]
      if (element && (element.innerText === everywhereText || element.innerText.toLowerCase() === everywhereText.toLowerCase())) {
        element.outerHTML = element.innerHTML
      }
    }
  } else {
    const element = editor.dom.select('mark#id' + place.toString())[0]
    if (element) {
      element.outerHTML = element.innerHTML
    }
  }
}

export function replaceInText (editor, popups, place, replacement, everywhereText) {
  if (everywhereText !== '') {
    for (let i in popups) {
      const element = editor.dom.select('mark#id' + i.toString())[0]
      if (element && element.innerText === everywhereText) {
        element.outerHTML =
          '<mark class="changed">' + replacement + '</mark>'
      }
    }
  } else {
    const element = editor.dom.select('mark#id' + place.toString())[0]
    if (element)
      element.outerHTML =
        '<mark class="changed">' + replacement + '</mark>'
  }
}

export function splitTextToArray (dataTokenizer, currentContent) {
  let splittedText = []
  if (dataTokenizer && dataTokenizer.length) {
    for (let i = 0; i < dataTokenizer.length; i++) {
      let preText = ''
      if (i === 0) {
        preText = currentContent.substring(0, dataTokenizer[0]['begin'])
      } else if (dataTokenizer[i]['begin'] > dataTokenizer[i - 1]['end']) {
        preText = currentContent.substring(dataTokenizer[i - 1]['end'], dataTokenizer[i]['begin'])
      }
      splittedText.push({
        pre: preText,
        dataTokenizer: dataTokenizer[i],
        text: currentContent.substring(dataTokenizer[i]['begin'], dataTokenizer[i]['end']),
        post: i === dataTokenizer.length - 1 ? currentContent.substring(dataTokenizer[i]['end'], currentContent.length) : ''
      })
    }
  }
  return splittedText
}

export function joinTextFromArray (splittedText) {
  let tmpContent = ''
  for (let i = 0; i < splittedText.length; i++) {
    tmpContent += splittedText[i]['pre'] + splittedText[i]['text'] + splittedText[i]['post']
  }
  return tmpContent
}

export function filterBenchmark (benchmarks, value, field) {
  let tmpBench = {}
  if (!Object.entries(benchmarks)?.length)
    return tmpBench
  Object.entries(benchmarks).map(([key, val]) => {
    if (val[field] === value) {
      tmpBench[key] = val
    }
    return null
  })
  return tmpBench
}

export async function readDNDFiles (token, tlService, acceptedFiles, translate, maxRequestSize, maxAnalizeTextSize) {
  if (acceptedFiles.length > 0) {
    let readFile
    let text = ''
    try {
      if (acceptedFiles[0].type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' || acceptedFiles[0].type === 'application/pdf')
        readFile = await readFileAsync(acceptedFiles[0], 'binary')
      else
        readFile = await readFileAsync(acceptedFiles[0], 'text')
    } catch (e) {
      return { error: { text: e.toString(), header: translate('File upload error') }, text: '' }
    }

    if (acceptedFiles[0].type === 'application/pdf') {
      try {
        const buffer = Buffer.from(readFile.result, 'binary')
        const base64String = buffer.toString('base64')
        const response = await tlService.convertPdf(token, acceptedFiles[0].name, base64String)
        return { error: null, text: response.html }
      } catch (e) {
        return { error: { text: e.toString(), header: translate('File conversion error') }, text: '' }
      }
    } else if (acceptedFiles[0].type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') {
      try {
        text = await mammothAsync(readFile.result)
        text = text
          .replaceAll(/\s+/g, ' ')
          .replaceAll(/(<table[^>]*>)(((?!<tbody>).)*?)(<\/table>)/g, `$1<tbody>$2</tbody>$4`)
      } catch (e) {
        return { error: { text: e.toString(), header: translate('File conversion error') }, text: '' }
      }
    } else if (acceptedFiles[0].type === 'application/msword') {
      text = readFile.result.toString().replace(/<body>(.+)<\/body>/, '$1')

    } else if (acceptedFiles[0].type === 'text/html') {
      text = readFile.result
      text = text.replaceAll(/\s+/g, ' ')
    } else if (acceptedFiles[0].type === 'text/plain') {
      text = convertPlainToHTML(readFile.result)
    }

    // check size
    const checkedSizeError = checkTextSize(maxRequestSize, maxAnalizeTextSize, text.length, stripHtml(text).length)
    if (checkedSizeError)
      return { error: { text: translate(checkedSizeError), header: translate(checkedSizeError) }, text: '' }
    return { error: null, text }

  }
  return { error: { text: translate('File upload error'), header: translate('File upload error') }, text: '' }
}

export async function startAnalyse (useBenchmark, textLanguage, text, tlService, token, translate) {

  let benchmarkRes
  try {
    benchmarkRes = await tlService.runBenchmark(token, useBenchmark, textLanguage, text)
  } catch (e) {
    return {
      error: { text: convertFromErrorObject(translate, e), header: translate('Analyse error') },
      resultTemplate: 0,
      resultData: {}
    }
  }
  if (benchmarkRes)
    return {
      error: null,
      resultTemplate: useBenchmark,
      resultData: benchmarkRes
    }
  return {
    error: { text: translate('Analyse error'), header: translate('Analyse error') },
    resultTemplate: 0,
    resultData: {}
  }
}

function getAllowListByKey (key, resultData, currentBenchmarkSettings, language) {
  const dataAllowedList = resultData['dataAllowedList']
  const dataAllowResult = resultData['dataAllowResult']
  const markAllowList = {}

  if (dataAllowResult !== undefined && dataAllowResult[key] !== undefined) {
    for (const position of dataAllowResult[key]) {
      // find in dataAllowedList
      for (const listId of Object.keys(dataAllowedList)) {
        if (listId !== 'local' && dataAllowedList[listId].category.relations.indexOf(key) > -1) {
          for (const res of dataAllowedList[listId].result) {
            for (const indexPosition in res.position) {
              if (res.position[indexPosition] === position) {
                let tmpResult = res

                // for backward compatability
                if (res['length'] === undefined) {
                  res['length'] = Array(res.position.length).fill(res.term.wordcount)
                }
                tmpResult['length'] = res['length']
                tmpResult.currentLength = res['length'][indexPosition]
                tmpResult.invisible = dataAllowedList[listId].category.settings?.invisible === '1' || dataAllowedList[listId].category.settings?.invisible === true
                tmpResult.allow = true
                tmpResult.category_settings = dataAllowedList[listId].category.settings
                tmpResult.category_name = dataAllowedList[listId].category.name[convertLanguagesToShort(language)]
                tmpResult.category_description = dataAllowedList[listId].category.description[convertLanguagesToShort(language)]
                if (markAllowList[position] === undefined) {
                  markAllowList[position] = tmpResult
                } else {
                  if (tmpResult.currentLength > markAllowList[position].currentLength) {
                    markAllowList[position] = tmpResult
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  // remove all invisible results
  for (const key of Object.keys(markAllowList)) {
    if (markAllowList[key].invisible) {
      delete markAllowList[key]
    }
  }
  return markAllowList
}

function checkForTags (splittedText, from, to) {
  if (from !== to && from > 0 && splittedText[from - 1].dataTokenizer.type === 'sgml') {
    const wordType = splittedText[from - 1].dataTokenizer.word.replace(/^</, '').replace(/>$/, '')
    for (let i = from; i <= to; i++) {
      if (splittedText[i].dataTokenizer.word === `</${wordType}>`) {
        return checkForTags(splittedText, from - 1, to)
      }
    }
  }
  return from
}

export function highlightEditor (element, key, resultFull, bodyBorderShow, text, ignoreList, language, currentBenchmarkSettings, translate, user) {
  console.log('highlightEditor', element, key)
  switch (element) {
    case 'Spell':
      return runSpellcheck(resultFull, text, key, ignoreList, bodyBorderShow, language, currentBenchmarkSettings, translate, user)
    case 'LinguisticClimate':
      return runLinguisticClimate(resultFull, text, key, ignoreList, bodyBorderShow, language, currentBenchmarkSettings, translate, user)
    case 'Readability':
      return runReadability(resultFull, text, key, ignoreList, bodyBorderShow, language, currentBenchmarkSettings, translate, user)
    case 'Terminology':
      return runTerminology(resultFull, text, key, ignoreList, bodyBorderShow, language, currentBenchmarkSettings, translate, user)
    case 'CorporateLanguage':
      return runCorporateLanguage(resultFull, text, key, ignoreList, bodyBorderShow, language, currentBenchmarkSettings, translate, user)
    case 'Tonality':
      return runTonality(resultFull, text, key, ignoreList, bodyBorderShow, language, currentBenchmarkSettings, translate, user)
    case 'GrammarAndStyle':
      return runGrammarAndStyle(resultFull, text, key, ignoreList, bodyBorderShow, language, currentBenchmarkSettings, translate, user)
    case 'Experimental':
      return runExperimental(resultFull, text, key, ignoreList, bodyBorderShow, language, currentBenchmarkSettings, translate, user)
    default:
      return { text, popups: [], ltOffset: 0 }
  }
}

// highlight functions

function runSpellcheck (resultFull, text, key, ignoreList = {}, bodyBorderShow, language, currentBenchmarkSettings, translate, user) {
  let popups = []
  let tmpContent = removeTagMark(text)
  let ltOffset = 0

  const dataSpell = deepCopy(resultFull.resultData[key] || [])
  const dataLanguageTool = deepCopy(resultFull.resultData?.languageTool || [])
  const dataTokenizer = resultFull.resultData.dataTokenizer
  const resultData = resultFull.resultData

  if (key === 'dataHunSpell') {

    // allow list
    const allowList = getAllowListByKey('dataHunSpell', resultFull.resultData, currentBenchmarkSettings, language)

    let splittedText = splitTextToArray(resultFull.resultData.dataTokenizer, tmpContent)

    // join two result
    for (const allowFrom of Object.keys(allowList)) {
      const newEl = {
        place: parseInt(allowFrom),
        res: allowList[allowFrom]
      }
      dataSpell.push(newEl)
    }

    dataSpell.sort((a, b) => a['place'] - b['place'])

    for (let i = 0; i < dataSpell.length; i++) {

      let colorClass = 'spell'

      const words = [splittedText[dataSpell[i]['place']]['text']]
      let from = dataSpell[i]['place']

      if (ignoreList['spell']?.everyWhereList.findIndex(value => isObjectsEqual(words, value)) > -1
        || isObjectsEqual(ignoreList['spell']?.byPositionList[from], words)) {
        continue
      }
      if (dataSpell[i]?.res?.allow) {
        colorClass = 'colorGreen'
        popups.push({
          index: i,
          component: 'AllowList',
          data: {
            noTranslate: true,
            words: splittedText[from],
            header: dataSpell[i].res.category_name,
            text: dataSpell[i].res?.term?.description?.length ? dataSpell[i].res?.term?.description : dataSpell[i].res.category_description,
            bestPractice: ``
          }
        })
      } else {
        popups.push({
          index: i,
          component: 'Spell',
          data: {
            words: [splittedText[from]['text']],
            suggestion: dataSpell[i]['suggestion'],
            text: splittedText[from]['text'],
            position: dataSpell[i]['place']
          }
        })
      }

      // update from
      from = checkForTags(splittedText, from, from)

      ltOffset += `<mark id="id${(popups.length - 1)}" class="${colorClass}">`.length + 7
      splittedText[from]['text'] = `<mark id="id${(popups.length - 1)}" class="${colorClass}">` + splittedText[from]['text'] + `</mark>`
    }

    if (bodyBorderShow) {
      splittedText = arrayAddBodyBorders(splittedText, resultFull.resultData)
    }

    // AddDataIgnored
    if (resultData?.dataIgnoredText?.length) {
      splittedText = textAddDataIgnoredArray(splittedText, resultData, translate)
    }

    return { text: joinTextFromArray(splittedText), popups, ltOffset }

  } else if (key === 'languageTool') {

    // allow list
    const allowList = getAllowListByKey('languageTool', resultFull.resultData, currentBenchmarkSettings, language)

    const startBody = resultFull?.resultData?.dataBodyRecognition && resultFull.resultData.dataBodyRecognition[0] > -1 ?
      resultFull.resultData.dataBodyRecognition[0] : 0
    const endBody = resultFull?.resultData?.dataBodyRecognition && resultFull.resultData.dataBodyRecognition[1] > -1 ?
      resultFull.resultData.dataBodyRecognition[1] : tmpContent.length - 1

    let borderOffset = 0
    if (bodyBorderShow) {
      borderOffset = resultFull?.resultData?.dataBodyRecognition && resultFull.resultData.dataBodyRecognition[0] > -1 ? 40 : 0
      tmpContent = textAddBodyBorders(tmpContent, resultFull.resultData)
    }

    // join two result
    for (const allowFrom of Object.keys(allowList)) {
      const newFrom = parseInt(allowFrom)
      const newTo = newFrom + parseInt(allowList[allowFrom]['term']['wordcount']) - 1
      const newEl = {
        offset: dataTokenizer[newFrom]?.begin,
        length: dataTokenizer[newTo]?.end - dataTokenizer[newFrom]?.begin,
        res: allowList[allowFrom]
      }
      dataLanguageTool.push(newEl)
    }

    // ignored
    if (resultData?.dataIgnoredText?.length) {
      for (const ignore of resultData.dataIgnoredText) {
        const newEl = {
          offset: ignore[0],
          length: ignore[1] - ignore[0],
          res: { ignore: true }
        }
        dataLanguageTool.push(newEl)
      }
    }

    // resort dataLanguageTool
    dataLanguageTool.sort((a, b) => b.offset - a.offset)

    let i = -1
    const dataLength = dataLanguageTool.filter(tmpSpell => tmpSpell?.res?.ignore !== true).length
    // let prevOffset = tmpContent.length;
    for (let tmpSpell of dataLanguageTool) {

      let colorClass = 'spell'

      const from = tmpSpell.offset
      const to = tmpSpell.offset + tmpSpell.length - 1

      if (from < startBody)
        continue
      if (to > endBody)
        continue
      i++

      const words = tmpContent.substring(tmpSpell.offset + borderOffset, tmpSpell.offset + tmpSpell.length + borderOffset)

      if (ignoreList['languageTool']?.everyWhereList.findIndex(value => isObjectsEqual([words], value)) > -1
        || isObjectsEqual(ignoreList['languageTool']?.byPositionList[tmpSpell.offset], [words])) {
        continue
      }

      if (tmpSpell?.res?.allow) {
        colorClass = 'colorGreen'
        popups.push({
          index: dataLength - 1 - i,
          component: 'AllowList',
          data: {
            noTranslate: true,
            words: tmpContent.slice(from + borderOffset, to + borderOffset),
            header: tmpSpell.res.category_name,
            text: tmpSpell.res?.term?.description?.length ? tmpSpell.res?.term?.description : tmpSpell.res.category_description,
            bestPractice: ``
          }
        })
      } else if (!tmpSpell?.res?.ignore) {
        popups.push({
          index: dataLength - 1 - i,
          component: 'LanguageTool',
          data: {
            description: tmpSpell?.message,
            words: words,
            suggestion: tmpSpell.replacements || [],
            text: words,
            position: tmpSpell.offset + borderOffset
          }
        })
      } else {
        i--
      }

      if (tmpSpell?.res?.ignore) {
        ltOffset += `<mark class="bgray" title="${translate('ignore_hover_text')}">`.length + 7
        tmpContent = tmpContent.substring(0, tmpSpell.offset + borderOffset) +
          `<mark class="bgray" title="${translate('ignore_hover_text')}">` +
          tmpContent.substring(tmpSpell.offset + borderOffset, tmpSpell.offset + tmpSpell.length + borderOffset) +
          `</mark>` +
          tmpContent.substring(tmpSpell.offset + tmpSpell.length + borderOffset)
      } else {
        ltOffset += `<mark id="id${dataLength - 1 - i}" class="${colorClass}">`.length + 7
        tmpContent = tmpContent.substring(0, tmpSpell.offset + borderOffset) +
          `<mark id="id${dataLength - 1 - i}" class="${colorClass}">` +
          tmpContent.substring(tmpSpell.offset + borderOffset, tmpSpell.offset + tmpSpell.length + borderOffset) +
          `</mark>` +
          tmpContent.substring(tmpSpell.offset + tmpSpell.length + borderOffset)
      }
      // prevOffset = tmpSpell.offset;
    }

    popups.reverse()

    return { text: tmpContent, popups, ltOffset }
  }

}

function runLinguisticClimate (resultFull, text, key, ignoreList = {}, bodyBorderShow, language, currentBenchmarkSettings, translate, user) {
  let ltOffset = 0
  const resultData = resultFull.resultData
  let popups = []
  let tmpContent = removeTagMark(text)
  let splittedText = splitTextToArray(resultData.dataTokenizer, tmpContent)

  const valCategoryTerm = resultData['dataColorWords']?.[key]
  const categoryAllTerm = valCategoryTerm?.result

  const categoryAllTermSorted = !!categoryAllTerm && categoryAllTerm.reduce((acc, val) => {
    const newVal = val.position.reduce((posAcc, posVal) => {
      return { ...posAcc, [posVal]: val }
    }, {})
    return { ...acc, ...newVal }
  }, {})

  let index = 0
  for (const valKey in categoryAllTermSorted) {

    // noinspection JSUnfilteredForInLoop
    const valTerm = categoryAllTermSorted[valKey]

    // noinspection JSUnfilteredForInLoop
    let from = parseInt(valKey)
    // noinspection JSUnfilteredForInLoop
    const to = parseInt(valKey) + parseInt(valTerm['term']['wordcount']) - 1

    const words = convertJSONToObject(valTerm['term']?.words)
    if (ignoreList['linguisticClimate']?.everyWhereList.findIndex(value => isObjectsEqual(words, value)) > -1
      || isObjectsEqual(ignoreList['linguisticClimate']?.byPositionList[from], words)) {
      continue
    }

    const arrText = []
    for (let i = valKey; i < parseInt(valKey) + parseInt(valTerm['term']['wordcount']); i++) {
      arrText.push(resultData.dataTokenizer[i]?.word)
    }

    // noinspection JSUnfilteredForInLoop
    popups.push({
      index: index++,
      component: 'LinguisticClimate',
      data: {
        arrText: arrText,
        words: words,
        term: valTerm['term'],
        replacement: valTerm['replacement'],
        inner_replacements: valTerm['inner_replacements'] || [],
        position: valTerm['position'],
        currentPosition: valKey,
        category: {
          category_id: key,
          category_type: valCategoryTerm['category']['category_type'],
          description: valCategoryTerm['category']['description'],
          locale_name: valCategoryTerm['category']['locale_name'],
          term_id: valCategoryTerm['category']['id'],
          settings: valCategoryTerm['category']['settings']
        }
      }
    })

    // update from
    from = checkForTags(splittedText, from, to)

    ltOffset += `<mark id="id${(popups.length - 1)}" class="${key}">`.length + 7
    splittedText[from]['text'] = `<mark id="id${(popups.length - 1)}" class="${key}">` + splittedText[from]['text']
    splittedText[to]['text'] = splittedText[to]['text'] + `</mark>`
  }

  if (bodyBorderShow) {
    splittedText = arrayAddBodyBorders(splittedText, resultData)
  }

  // AddDataIgnored
  if (resultData?.dataIgnoredText?.length) {
    splittedText = textAddDataIgnoredArray(splittedText, resultData, translate)
  }

  const textResult = joinTextFromArray(splittedText)
  return { text: textResult, popups, ltOffset }
}

function runReadability (resultFull, text, key, ignoreList = {}, bodyBorderShow, language, currentBenchmarkSettings, translate, user) {
  let ltOffset = 0
  const resultData = resultFull.resultData
  let popups = []
  let tmpContent = removeTagMark(text)
  let splittedText = splitTextToArray(resultData.dataTokenizer, tmpContent)

  const valCategoryTerm = deepCopy(resultData[key])

  // allow list
  const allowList = getAllowListByKey(key, resultData, currentBenchmarkSettings, language)

  // add allow list to result
  for (const allowFrom of Object.keys(allowList)) {
    const newTo = parseInt(allowFrom) + parseInt(allowList[allowFrom]['term']['wordcount']) - 1
    const newVal = [
      parseInt(allowFrom),
      newTo,
      allowList[allowFrom]
    ]
    valCategoryTerm.push(newVal)
  }

  // sort result by position
  valCategoryTerm.sort((a, b) => a[0] - b[0])

  for (const valTerm of valCategoryTerm) {
    let from, to
    let colorClass = 'readability'
    if (valTerm[2] !== undefined && valTerm[2]?.allow) {
      colorClass = 'colorGreen'
      from = findToNotHTML(splittedText, valTerm[0], splittedText.length, 1)
      to = findToNotHTML(splittedText, valTerm[1], 0, -1)
      popups.push({
        index: popups.length,
        component: 'AllowList',
        data: {
          noTranslate: true,
          words: splittedText.slice(from, to),
          header: valTerm[2].category_name,
          text: valTerm[2]?.term?.description?.length ? valTerm[2]?.term?.description : valTerm[2].category_description,
          bestPractice: ``
        }
      })
    } else {
      if (config.multiWords.indexOf(key) > -1) {
        // many words
        from = findToNotHTML(splittedText, valTerm[0], splittedText.length, 1)
        to = findToNotHTML(splittedText, valTerm[1], 0, -1)
      } else {
        //one word
        from = valTerm[0]
        to = valTerm[0]
      }

      const keyNN = `${key}_suggestions_${convertLanguagesToShort(currentBenchmarkSettings.locale_name)}`
      const enabledFrontendSections = user['enabled_frontend_sections'] || []

      if (enabledFrontendSections.indexOf(keyNN) > -1) {
        popups.push({
          index: popups.length,
          component: 'ReadabilitySuggestion',
          data: {
            numSuggestions: 3,
            language: convertLanguagesToShort(currentBenchmarkSettings.locale_name),
            sectionName: key + '_suggestions',
            valueName: key,
            words: splittedText.slice(from, to),
            header: `${key}_modal_header`,
            text: `${key}_modal_text`,
            bestPractice: `${key}_modal_bestPractice`
          }
        })
      } else {
        popups.push({
          index: popups.length,
          component: 'Readability',
          data: {
            valueName: key,
            words: splittedText.slice(from, to),
            header: `${key}_modal_header`,
            text: `${key}_modal_text`,
            bestPractice: `${key}_modal_bestPractice`
          }
        })
      }
    }

    // update from
    from = checkForTags(splittedText, from, to)

    ltOffset += `<mark id="id${(popups.length - 1)}" class="${colorClass}">`.length + 7
    splittedText[from]['text'] = `<mark id="id${(popups.length - 1)}" class="${colorClass}">` + splittedText[from]['text']
    splittedText[to]['text'] = splittedText[to]['text'] + `</mark>`
  }

  if (bodyBorderShow) {
    splittedText = arrayAddBodyBorders(splittedText, resultData)
  }

  // AddDataIgnored
  if (resultData?.dataIgnoredText?.length) {
    splittedText = textAddDataIgnoredArray(splittedText, resultData, translate)
  }

  const textResult = joinTextFromArray(splittedText)
  return { text: textResult, popups, ltOffset }
}

//dataTextClassification
function runExperimental (resultFull, text, key, ignoreList = {}, bodyBorderShow, language, currentBenchmarkSettings, translate, user) {
  let ltOffset = 0
  const resultData = resultFull.resultData
  let popups = []
  let tmpContent = removeTagMark(text)
  let splittedText = splitTextToArray(resultData.dataTokenizer, tmpContent)

  const modalSettings = {
    positive: { colorClass: 'colorGreen', header: 'header positive', text: 'body positive', bestPractice: '' },
    neutral: { colorClass: 'colorBlue', header: 'header neutral', text: 'body neutral', bestPractice: '' },
    negative: { colorClass: 'colorRed', header: 'header negative', text: 'body negative', bestPractice: '' },
  }

  const valCategoryTerm = deepCopy(resultData[key])

  // sort result by position
  valCategoryTerm.sort((a, b) => a[0] - b[0])

  for (const valTerm of valCategoryTerm) {
    let from, to
    from = findToNotHTML(splittedText, valTerm[0], splittedText.length, 1)
    to = findToNotHTML(splittedText, valTerm[1], 0, -1)

    popups.push({
      index: popups.length,
      component: 'TextClassification',
      data: {
        words: splittedText.slice(from, to),
        header: modalSettings[valTerm[2]].header,
        text: modalSettings[valTerm[2]].text,
        bestPractice: modalSettings[valTerm[2]].bestPractice
      }
    })
    const colorClass = modalSettings[valTerm[2]].colorClass

    // update from
    from = checkForTags(splittedText, from, to)

    ltOffset += `<mark id="id${(popups.length - 1)}" class="${colorClass}">`.length + 7
    splittedText[from]['text'] = `<mark id="id${(popups.length - 1)}" class="${colorClass}">` + splittedText[from]['text']
    splittedText[to]['text'] = splittedText[to]['text'] + `</mark>`
  }

  if (bodyBorderShow) {
    splittedText = arrayAddBodyBorders(splittedText, resultData)
  }

  const textResult = joinTextFromArray(splittedText)
  return { text: textResult, popups, ltOffset }
}

function runGrammarAndStyle (resultFull, text, key, ignoreList = {}, bodyBorderShow, language, currentBenchmarkSettings, translate, user) {

  let ltOffset = 0
  const resultData = resultFull.resultData
  let popups = []
  let tmpContent = removeTagMark(text)
  let splittedText = splitTextToArray(resultData.dataTokenizer, tmpContent)

  const valCategoryTerm = resultData[key]

  if (valCategoryTerm !== undefined) {
    for (const valTerm of valCategoryTerm) {

      const tFrom = typeof valTerm === 'object' ? valTerm[0] : valTerm
      const tTo = typeof valTerm === 'object' ? valTerm[1] : valTerm

      let from = findToNotHTML(splittedText, tFrom, splittedText.length, 1)
      const to = findToNotHTML(splittedText, tTo, 0, -1)

      //dirty hack
      if (key === 'countMarkExclamation') {
        from = findToNotHTML(splittedText, from - 1, 0, -1)
      }

      popups.push({
        index: popups.length,
        component: 'GrammarAndStyle',
        data: {
          numSuggestions: 3,
          language: convertLanguagesToShort(currentBenchmarkSettings.locale_name),
          sectionName: key + '_suggestions',
          words: splittedText.slice(from, to),
          header: `${key}_modal_header`,
          text: `${key}_modal_text`,
          bestPractice: `${key}_modal_bestPractice`
        }
      })

      // update from
      from = checkForTags(splittedText, from, to)

      ltOffset += `<mark id="id${(popups.length - 1)}" class="grammarandstyle">`.length + 7
      splittedText[from]['text'] = `<mark id="id${(popups.length - 1)}" class="grammarandstyle">` + splittedText[from]['text']
      splittedText[to]['text'] = splittedText[to]['text'] + `</mark>`
    }
  }

  if (bodyBorderShow) {
    splittedText = arrayAddBodyBorders(splittedText, resultData)
  }

  // AddDataIgnored
  if (resultData?.dataIgnoredText?.length) {
    splittedText = textAddDataIgnoredArray(splittedText, resultData, translate)
  }

  const textResult = joinTextFromArray(splittedText)
  return { text: textResult, popups, ltOffset }
}

function runTerminology (resultFull, text, key, ignoreList = {}, bodyBorderShow, language, currentBenchmarkSettings, translate, user) {
  let ltOffset = 0
  const resultData = resultFull.resultData
  let popups = []
  let tmpContent = removeTagMark(text)
  let splittedText = splitTextToArray(resultData.dataTokenizer, tmpContent)

  const valCategoryTerm = resultData['dataTerms'][key]
  const categoryType = valCategoryTerm?.category?.category_type
  const categoryAllTerm = valCategoryTerm?.result

  const categoryAllTermSorted = !!categoryAllTerm && categoryAllTerm.reduce((acc, val) => {
    const newVal = !!val.position && val.position.reduce((posAcc, posVal) => {
      return { ...posAcc, [posVal]: val }
    }, {})
    return { ...acc, ...newVal }
  }, {})

  if (categoryType !== 'positive') {
    // allow list
    const allowList = getAllowListByKey(key, resultData, currentBenchmarkSettings, language)

    // join two result (allowList will be overwritten by categoryAllTermSorted)
    for (const allowFrom of Object.keys(allowList)) {
      categoryAllTermSorted[allowFrom] = allowList[allowFrom]
    }
  }

  let index = 0
  for (const valKey of Object.keys(categoryAllTermSorted).sort((a, b) => a - b)) {

    let colorClass = valCategoryTerm.category?.settings?.color || (categoryType === 'negative' ? 'colorRed' : 'colorGreen')

    // noinspection JSUnfilteredForInLoop
    const valTerm = categoryAllTermSorted[valKey]
    // noinspection JSUnfilteredForInLoop
    let from = parseInt(valKey)
    const length = valTerm['length'] ? valTerm['length'][valTerm['position'].indexOf(from)] : 0
    // noinspection JSUnfilteredForInLoop
    const wordcount = parseInt(valTerm['term']['wordcount'])
    let to = from + wordcount - 1
    if (!!length && length > wordcount) {
      to = from + length - 1
    }

    const words = convertJSONToObject(valTerm['term'].words)
    if (ignoreList['terminology']?.everyWhereList.findIndex(value => isObjectsEqual(words, value)) > -1
      || isObjectsEqual(ignoreList['terminology']?.byPositionList[from], words)) {
      continue
    }

    // if allow list
    let popupDescription = valTerm?.term?.description?.length ? valTerm.term.description :
      (valCategoryTerm.category.description[convertLanguagesToShort(language)] !== undefined
        ? valCategoryTerm.category.description[convertLanguagesToShort(language)]
        : '')
    if (valTerm?.allow) {
      colorClass = 'colorGreen'
      popups.push({
        index: index++,
        component: 'AllowList',
        data: {
          noTranslate: true,
          words: splittedText.slice(from, to),
          header: valTerm.category_name,
          text: valTerm?.term?.description?.length ? valTerm?.term?.description : valTerm.category_description,
          bestPractice: ``
        }
      })
    } else if (categoryType === 'positive') {
      popups.push({
        index: index++,
        component: 'PositiveTerminology',
        data: {
          noTranslate: true,
          words: splittedText.slice(from, to),
          header: valCategoryTerm.category.name[convertLanguagesToShort(language)] !== undefined ? valCategoryTerm.category.name[convertLanguagesToShort(language)] : '',
          text: popupDescription,
          bestPractice: ``
        }
      })
    } else {
      const arrText = []
      for (let i = valKey; i < parseInt(valKey) + parseInt(valTerm['term']['wordcount']); i++) {
        arrText.push(resultData.dataTokenizer[i]?.word)
      }
      popups.push({
        index: index++,
        component: 'Terminology',
        data: {
          arrText: arrText,
          words: words,
          term: valTerm['term'],
          replacement: valTerm['replacement'],
          inner_replacements: valTerm['inner_replacements'] || [],
          position: valTerm['position'],
          currentPosition: valKey,
          category: {
            allowListDisabled: valCategoryTerm.category?.settings?.allowListDisabled,
            ignoreDisabled: valCategoryTerm.category?.settings?.ignoreDisabled,
            category_id: key,
            name: valCategoryTerm.category['name'][convertLanguagesToShort(language)],
            category_type: valCategoryTerm.category['category_type'],
            description: valCategoryTerm.category['description'][convertLanguagesToShort(language)],
            locale_name: valCategoryTerm.category['locale_name']

          }
        }
      })
    }

    // update from
    from = checkForTags(splittedText, from, to)

    ltOffset += `<mark id="id${(popups.length - 1)}" class="${colorClass}">`.length + 7
    splittedText[from]['text'] = `<mark id="id${(popups.length - 1)}" class="${colorClass}">` + splittedText[from]['text']
    splittedText[to]['text'] = splittedText[to]['text'] + `</mark>`
  }

  if (bodyBorderShow) {
    splittedText = arrayAddBodyBorders(splittedText, resultData)
  }

  // AddDataIgnored
  if (resultData?.dataIgnoredText?.length) {
    splittedText = textAddDataIgnoredArray(splittedText, resultData, translate)
  }

  const textResult = joinTextFromArray(splittedText)
  return { text: textResult, popups, ltOffset }
}

function runCorporateLanguage (resultFull, text, key, ignoreList = {}, bodyBorderShow, language, currentBenchmarkSettings, translate, user) {

  let ltOffset = 0
  const resultData = resultFull.resultData
  let popups = []
  let tmpContent = removeTagMark(text)
  let splittedText = splitTextToArray(resultData.dataTokenizer, tmpContent)

  const valCategoryTerm = resultData['dataCorporateLanguage'][key]
  const categoryType = valCategoryTerm?.category?.category_type
  const categoryAllTerm = valCategoryTerm?.result

  let categoryAllTermSorted = !!categoryAllTerm && categoryAllTerm.reduce((acc, val) => {
    const newVal = val.position.reduce((posAcc, posVal) => {
      return { ...posAcc, [posVal]: val }
    }, {})
    return { ...acc, ...newVal }
  }, {})

  if (categoryType !== 'positive') {
    // allow list
    const allowList = getAllowListByKey(key, resultData, currentBenchmarkSettings, language)

    // join two result (allowList will be overwritten by categoryAllTermSorted)
    for (const allowFrom of Object.keys(allowList)) {
      categoryAllTermSorted[allowFrom] = allowList[allowFrom]
    }
  }

  let index = 0
  for (const valKey of Object.keys(categoryAllTermSorted).sort((a, b) => a - b)) {

    let colorClass = valCategoryTerm.category?.settings?.color || (categoryType === 'negative' ? 'colorRed' : 'colorGreen')

    // noinspection JSUnfilteredForInLoop
    const valTerm = categoryAllTermSorted[valKey]
    let from = parseInt(valKey)
    const length = valTerm['length'] ? valTerm['length'][valTerm['position'].indexOf(from)] : 0
    // noinspection JSUnfilteredForInLoop
    const wordcount = parseInt(valTerm['term']['wordcount'])
    let to = from + wordcount - 1
    if (!!length && length > wordcount) {
      to = from + length - 1
    }

    const words = convertJSONToObject(valTerm['term'].words)
    if (ignoreList['dataCorporateLanguage']?.everyWhereList.findIndex(value => isObjectsEqual(words, value)) > -1
      || isObjectsEqual(ignoreList['dataCorporateLanguage']?.byPositionList[from], words)) {
      continue
    }

    let popupDescription = valTerm?.term?.description?.length ? valTerm.term.description :
      (valCategoryTerm.category.description[convertLanguagesToShort(language)] !== undefined
        ? valCategoryTerm.category.description[convertLanguagesToShort(language)]
        : '')
    if (valTerm?.allow) {
      colorClass = 'colorGreen'
      popups.push({
        index: index++,
        component: 'AllowList',
        data: {
          noTranslate: true,
          words: splittedText.slice(from, to),
          header: valTerm.category_name,
          text: valTerm?.term?.description?.length ? valTerm?.term?.description : valTerm.category_description,
          bestPractice: ``
        }
      })
    } else if (categoryType === 'positive') {
      popups.push({
        index: index++,
        component: 'PositiveTerminology',
        data: {
          noTranslate: true,
          words: splittedText.slice(from, to),
          header: valCategoryTerm.category.name[convertLanguagesToShort(language)] !== undefined ? valCategoryTerm.category.name[convertLanguagesToShort(language)] : '',
          text: popupDescription,
          bestPractice: ``
        }
      })
    } else {
      const arrText = []
      for (let i = valKey; i < parseInt(valKey) + parseInt(valTerm['term']['wordcount']); i++) {
        arrText.push(resultData.dataTokenizer[i]?.word)
      }
      popups.push({
        index: index++,
        component: 'Terminology',
        data: {
          arrText: arrText,
          words: words,
          term: valTerm['term'],
          replacement: valTerm['replacement'],
          inner_replacements: valTerm['inner_replacements'] || [],
          position: valTerm['position'],
          currentPosition: valKey,
          category: {
            allowListDisabled: valCategoryTerm.category?.settings?.allowListDisabled,
            ignoreDisabled: valCategoryTerm.category?.settings?.ignoreDisabled,
            category_id: key,
            name: valCategoryTerm.category['name'][convertLanguagesToShort(language)],
            category_type: valCategoryTerm.category['category_type'],
            description: valCategoryTerm.category['description'][convertLanguagesToShort(language)],
            locale_name: valCategoryTerm.category['locale_name']

          }
        }
      })
    }

    // update from
    from = checkForTags(splittedText, from, to)

    ltOffset += `<mark id="id${(popups.length - 1)}" class="${colorClass}">`.length + 7
    splittedText[from]['text'] = `<mark id="id${(popups.length - 1)}" class="${colorClass}">` + splittedText[from]['text']
    splittedText[to]['text'] = splittedText[to]['text'] + `</mark>`
  }

  if (bodyBorderShow) {
    splittedText = arrayAddBodyBorders(splittedText, resultData)
  }

  // AddDataIgnored
  if (resultData?.dataIgnoredText?.length) {
    splittedText = textAddDataIgnoredArray(splittedText, resultData, translate)
  }

  const textResult = joinTextFromArray(splittedText)
  return { text: textResult, popups, ltOffset }
}

function runTonality (resultFull, text, key, ignoreList = {}, bodyBorderShow, language, currentBenchmarkSettings, translate, user) {
  let ltOffset = 0
  const resultData = resultFull.resultData
  let popups = []
  let tmpContent = removeTagMark(text)
  let splittedText = splitTextToArray(resultData.dataTokenizer, tmpContent)
  let listCat = []
  if (key === 'formulaPersonalNeutralScaleValue')
    listCat = [['dataTonalityNeutral', 'dataTonalityPersonal'],
      ['countTonalityClauseNeutral', 'countTonalityClausePersonal']]
  else if (key === 'formulaTonalityScaleValue')
    listCat = [['dataTonalityNegative', 'dataTonalityPositive'],
      ['countTonalityClauseNegative', 'countTonalityClausePositive']]

  const tonality = listCat[0]
  let id = 0

  for (let categoryType of tonality) {
    const categoryAllTerm = Object.values(resultData[categoryType])
    if (categoryAllTerm) {
      for (const valCategoryTerm of categoryAllTerm) {
        for (const i of valCategoryTerm['position']) {

          // update from
          const from = checkForTags(splittedText, i, i + valCategoryTerm['term']['wordcount'] - 1)

          ltOffset += `<mark id="id${id++}" class="${categoryType}">`.length + 7
          splittedText[from]['text'] = `<mark id="id${id++}" class="${categoryType}">` + splittedText[from]['text']
          splittedText[i + valCategoryTerm['term']['wordcount'] - 1]['text'] = splittedText[i + valCategoryTerm['term']['wordcount'] - 1]['text'] + `</mark>`

        }
      }
    }
  }

  const clauses = listCat[1]

  for (let clauseType of clauses) {
    const categoryAllTerm = Object.values(resultData[clauseType])
    if (categoryAllTerm) {
      for (const valCategoryTerm of categoryAllTerm) {

        // update from
        const from = checkForTags(splittedText, valCategoryTerm[0], valCategoryTerm[1])

        ltOffset += `<mark id="id${id++}" class="${clauseType}">`.length + 7
        splittedText[from]['text'] = `<mark id="id${id++}" class="${clauseType}">` + splittedText[from]['text']
        splittedText[valCategoryTerm[1]]['text'] = splittedText[valCategoryTerm[1]]['text'] + `</mark>`
      }
    }
  }

  if (bodyBorderShow) {
    splittedText = arrayAddBodyBorders(splittedText, resultData)
  }

  // AddDataIgnored
  if (resultData?.dataIgnoredText?.length) {
    splittedText = textAddDataIgnoredArray(splittedText, resultData, translate)
  }

  const textResult = joinTextFromArray(splittedText)
  return { text: textResult, popups, ltOffset }
}
