import React, { useEffect, useState } from "react";
import style from "./style.module.sass";
import arrow from "../../../../Profile/ProfileWrapper/assets/arrow.svg";
import {NavLink} from "react-router-dom";
import {useTheme} from "@material-ui/core/styles";
import groupIcon from "../../../Info/assets/groupIcon.svg"
import {Button, Dialog, DialogActions, DialogContent, DialogContentText} from "@material-ui/core";
import saveIcon from "../../../Info/assets/saveIcon.svg";
import {useFormik} from 'formik';
import * as yup from 'yup';
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux"
import TLServiceComponent from "../../../../../contexts/TLServiceComponent"
import { useParams } from 'react-router'
import GroupProfile from "./GroupProfile/GroupProfile"
import Options from "./Options"
import { toast, ToastContainer } from "react-toastify"
import { checkSharedData, checkUserRight, convertFromErrorObject, isObjectEmpty } from "../../../../../utils"
import { Add } from "@material-ui/icons"
import { useActions } from "../../../../../utils/action-helper"
import { tableSetDialog } from "../../../../../actions"
import RequestComponent from "../../../User/Table/TableInnerComponent/ReqComponent"
import Spinner from "../../../../Spinner"

//state validation by YUP
const validationSchema = yup.object({
  groupName: yup
  .string('Enter your group name')
  .required('Group name is required'),
  enabled_frontend_sections: yup
  .array()
  .of(yup.string(),'Enabled frontend sections must be a string')
  .default([]),
});

function Form() {
  const {theme: {bgColors, colors}} = useTheme()
  const { t } = useTranslation();
  const [companyList, setCompanyList] = useState([])
  const [spinner, setSpinner] = useState(false)
  const user = useSelector(state => state.userData.user)
  const token = useSelector((state) => state.userData.token)
  const groups = useSelector((state) => state.adminData.groups)
  const { id } = useParams()
  const dialog = useSelector(state => state.tableData.dialog)
  const dialogOpen = useSelector(state => state.tableData.dialog.open)
  const DialogRequestComponent = useSelector(state => state.tableData.dialog.RequestComponent)
  const dialogButtons = useSelector(state => state.tableData.dialog.buttons)
  const [localGroups, setLocalGroups] = useState([])
  const { aTableSetDialog } = useActions({ aTableSetDialog: tableSetDialog })


  const tlService = React.useContext(TLServiceComponent)

  const formik = useFormik({
    initialValues: {
      groupName: "",
      company: "",
      groupId: "",
      create_time: null,
      update_time: null,
      enabled_frontend_sections: [],
      edit_archives: '',
      view_archives: '',
      enable_textbin: ''
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      postGroup(values)
    },
  });

  const fillFormik = async () => {
    let filterIs
    let groups
    filterIs = { id: id }
    if (!!id) {
      try {
        groups = await tlService.getFilteredGroups(token, {}, filterIs)
        setLocalGroups(groups)
      } catch (e) {
        console.log('error', e)
      }
       // Set the Values if User try to EDIT:
       formik.initialValues.groupName = groups.data[0].name
       formik.initialValues.company = groups.data[0].company_id
       formik.initialValues.groupId = groups.data[0].id
       formik.initialValues.create_time = groups.data[0].create_time ? groups.data[0].create_time : null
       formik.initialValues.update_time = groups.data[0].update_time ? groups.data[0].update_time : null
        if (!isObjectEmpty(groups.data[0].user_group_options)) {
         for (const tmpOption of Object.keys(groups.data[0].user_group_options)) {
           formik.initialValues[tmpOption] = groups.data[0].user_group_options[tmpOption]
         }
      }
      
  } else {
      // Set the Values to empty if User try to ADD new User:
      formik.initialValues.groupName = ''
      formik.initialValues.company = ''
      formik.initialValues.enabled_frontend_sections = []
      formik.initialValues.edit_archives = ''
      formik.initialValues.view_archives = ''
      formik.initialValues.enable_textbin = ''
  }
      // Get company list:
      let company
      try {
        company = await tlService.getFilteredCompanies(token, {}, {})
      } catch (err) {
        console.log('error', err)
      }
      let companiesList = []
      !!company?.data && company.data.map((el) => {
        let obj = {}
        obj.id = el.id
        obj.name = el.name
        companiesList.push(obj)
      })
      setCompanyList(companiesList)
}

  const postGroup = async (values) => {
    const options = Object.keys(values).filter(option => option !== 'groupName' && option !== 'company' && option !== 'create_time' && option !== 'update_time' && option !== 'groupId')

    if (!!id) {
      const updateGroup = async () => {
        try {
          await tlService.updateGroup(token, parseInt(id), parseInt(values.company), values.groupName)
          toast.success(t('admin_group_update-success-msg'))
        } catch (e) {
          console.log('error', e)
          toast.error(convertFromErrorObject(t, e))
          return false
        }

        // options
        if (checkUserRight(user, [104])) {
          try {
            await tlService.deleteGroupOption(token, parseInt(id))
          } catch (e) {
            console.log('error ' + convertFromErrorObject(t, e))
            toast.error(convertFromErrorObject(t, e))
            return false
          }

          for (let tmpOption of options) {
            const value = values[tmpOption]
            if (isValidValue(value)) {
              const value = typeof values[tmpOption] === 'object' ? JSON.stringify(values[tmpOption]) : String(values[tmpOption]).trim()
              try {
                await tlService.addGroupOption(token, parseInt(id), tmpOption, value)
              } catch (e) {
                console.log('error', e)
                toast.error(convertFromErrorObject(t, e))
                return false
              }
            }
          }
        }
        return true
      }

      if (localGroups.data[0].company_id !== values.company && !dialogOpen) {
        setSpinner(true)

        let { textBins, allowedLists, sharedArchivesInGroup, archiveFoundInFolderGroup, benchmarksInGroup } = await checkSharedData(tlService, token, localGroups.data[0], t, "group")
        setSpinner(false)


        if (textBins?.fullcount > 0 || allowedLists?.fullcount > 0 || sharedArchivesInGroup.archives_fullcount > 0 || benchmarksInGroup.fullcount > 0) {
          aTableSetDialog({
            type: 'editGroupWarning',
            open: true,
            RequestComponent: () => (
              <RequestComponent
                t={t}
                archiveFoundInFolderGroup={archiveFoundInFolderGroup}
                sharedArchivesInGroup={sharedArchivesInGroup}
                benchmarksInGroup={benchmarksInGroup}
                textBins={textBins}
                allowedLists={allowedLists}
                type='group'
                warningType="edit"
              />
            ),
            response: null,
            buttons: {
              yes: false,
              no: false,
              close: true,
              save: false,
              edit: true,
            }
          })

        } else {
          updateGroup()
        }

      } else {
        updateGroup()
      }
    }
    else {
      let groupId = false
      try {
        const res = await tlService.addGroup(token, parseInt(user.company_id), values.groupName)
        toast.success(t('admin_group_add-success-msg'))
        groupId = res.id
      }
      catch (e) {
        console.log('error', e)
        toast.error(convertFromErrorObject(t, e))
        return false
      }

      if (checkUserRight(user, [104])) {
        for (let tmpOption of options) {
          const value = values[tmpOption]
          if (isValidValue(value)) {
            const value = typeof values[tmpOption] === 'object' ? JSON.stringify(values[tmpOption]) : String(values[tmpOption]).trim()
            try {
              await tlService.addGroupOption(token, parseInt(groupId), tmpOption, value)
            } catch (e) {
              console.log('error', e)
              toast.error(convertFromErrorObject(t, e))
              return false
            }
          }
        }
      }
      return true
    }
  }

  function isValidValue(value) {
    return value !== undefined && value !== '' && (typeof value !== 'object' || Object.keys(value).length > 0);
  }

  useEffect(() => {
    fillFormik()
  }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  , [])

  const isSingleGroupUser = Object.keys(groups).length === 1 
  if (spinner)
    return <div className={'w-100 align-content-center text-center align-items-center'}><Spinner /></div>

  return (
    <>
    <ToastContainer autoClose={5000} position="top-center" hideProgressBar={false} draggable pauseOnHover closeOnClick/>
    <Dialog
        open={!!dialogOpen}
        onClose={() => aTableSetDialog({ ...dialog, response: null })}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent style={{ minWidth: '300px' }}>
          <DialogContentText id="alert-dialog-description">
            <DialogRequestComponent />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {dialogButtons.edit &&
            <Button onClick={
              () => {
                aTableSetDialog({
                  ...dialog, open: false, response: null
                })
                postGroup(formik.values)
              }} >
              {t('admin_group_edit-anyway')}
            </Button>}
          {dialogButtons.close &&
            <Button onClick={
              () => {
                aTableSetDialog({ ...dialog, response: null, open: false })
              }}>
              {t('Close')}
            </Button>}
        </DialogActions>
      </Dialog>
    <form className={`pb-5 ${style.mainCont}`} onSubmit={formik.handleSubmit}>
      <NavLink className={`${style.breadcrumb}`} to={
        isSingleGroupUser ? "/administration" : "/administration/groups"
      } exact={true}>
        <Button color="primary" variant="contained">
          <img src={arrow} className={`mr-3`} alt=""/>
          {
            isSingleGroupUser ? t('administration') : t('groups')
          }
        </Button>
      </NavLink>
        {
          !!id && isSingleGroupUser && (
            <div style={{ float: 'right', padding: '5px' }}>
              {checkUserRight(user, [207]) &&
                <NavLink to={`/administration/group/update/add-new-group`} exact={true}>
                  <Button variant="contained"
                    style={{ whiteSpace: "nowrap" }}
                    color="primary"
                    startIcon={<Add />}>
                    {t('admin_user_add-new-group')}
                  </Button>
                </NavLink>
              }
            </div>
          )
        }
      <div className={`mt-5`}>
        <div className={`d-flex align-items-center justify-content-between mb-3`}>
          <h3 style={{color: colors.colorPrimary1000}} className={`${style.main_title} d-flex align-items-center`}>
            <img style={{height: 50, width: 50}}
                  className={`mr-3`} src={groupIcon} alt=""/>
            {t('groups')}</h3>
          <Button type="submit" variant={"contained"} color={"primary"}
                  style={{backgroundColor: bgColors.bgBlue1000, color: colors.colorPrimary0}}
                  className={`mr-2`}>
            <img src={saveIcon} className={`mr-2`} alt=""/> {t('save-changes')}
          </Button>
        </div>
        <div style={{backgroundColor: bgColors.bgPrimary500}}
             className={`pl-0 mb-4 pb-3 pr-0 col-12 ${style.borderTop}`}>
          <GroupProfile formik={formik} companies={companyList} id={id}/>
          <Options formik={formik} id={id}/>
        </div>
        <div className={`${style.saveChangesBtn} mb-5`}>
          <Button type="submit" variant={"contained"} color={"primary"}
                  style={{backgroundColor: bgColors.bgBlue1000, color: colors.colorPrimary0}}
                  className={`mr-2`}>
            <img src={saveIcon} className={`mr-2`} alt=""/> {t('save-changes')}
          </Button>
        </div>
      </div>
    </form>
    </>
  )
}

export default Form;
